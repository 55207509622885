/* eslint-disable */ 
// source: sip/people/people.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js');
goog.object.extend(proto, google_protobuf_empty_pb);
var google_protobuf_field_mask_pb = require('google-protobuf/google/protobuf/field_mask_pb.js');
goog.object.extend(proto, google_protobuf_field_mask_pb);
var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var google_type_date_pb = require('../../google/type/date_pb.js');
goog.object.extend(proto, google_type_date_pb);
goog.exportSymbol('proto.sip.people.AuditLogEntry', null, global);
goog.exportSymbol('proto.sip.people.AuditLogEntry.Filter', null, global);
goog.exportSymbol('proto.sip.people.AuditLogEntry.Filter.SortBy', null, global);
goog.exportSymbol('proto.sip.people.AuditLogEntry.View', null, global);
goog.exportSymbol('proto.sip.people.AuthorizationRole', null, global);
goog.exportSymbol('proto.sip.people.BankAccount', null, global);
goog.exportSymbol('proto.sip.people.BatchGetGroupRequest', null, global);
goog.exportSymbol('proto.sip.people.BatchGetGroupResponse', null, global);
goog.exportSymbol('proto.sip.people.BatchGetUserRequest', null, global);
goog.exportSymbol('proto.sip.people.BatchGetUserResponse', null, global);
goog.exportSymbol('proto.sip.people.ChangeGroupMembersRequest', null, global);
goog.exportSymbol('proto.sip.people.ChangeGroupMembersRequest.ChangeGroupMembersAction', null, global);
goog.exportSymbol('proto.sip.people.CreateGroupRequest', null, global);
goog.exportSymbol('proto.sip.people.CreateUserRequest', null, global);
goog.exportSymbol('proto.sip.people.DeleteGroupRequest', null, global);
goog.exportSymbol('proto.sip.people.DeleteUserRequest', null, global);
goog.exportSymbol('proto.sip.people.EmploymentInformation', null, global);
goog.exportSymbol('proto.sip.people.EthStudyInfo', null, global);
goog.exportSymbol('proto.sip.people.GetGroupRequest', null, global);
goog.exportSymbol('proto.sip.people.GetUserRequest', null, global);
goog.exportSymbol('proto.sip.people.GlobalScope', null, global);
goog.exportSymbol('proto.sip.people.Group', null, global);
goog.exportSymbol('proto.sip.people.Group.Filter', null, global);
goog.exportSymbol('proto.sip.people.Group.Filter.SortBy', null, global);
goog.exportSymbol('proto.sip.people.Group.View', null, global);
goog.exportSymbol('proto.sip.people.GroupObjectType', null, global);
goog.exportSymbol('proto.sip.people.ListAuditLogEntriesRequest', null, global);
goog.exportSymbol('proto.sip.people.ListAuditLogEntriesResponse', null, global);
goog.exportSymbol('proto.sip.people.ListAuthorizationRolesResponse', null, global);
goog.exportSymbol('proto.sip.people.ListGroupsRequest', null, global);
goog.exportSymbol('proto.sip.people.ListGroupsResponse', null, global);
goog.exportSymbol('proto.sip.people.ListUsersRequest', null, global);
goog.exportSymbol('proto.sip.people.ListUsersResponse', null, global);
goog.exportSymbol('proto.sip.people.PostalAddress', null, global);
goog.exportSymbol('proto.sip.people.ResourceScope', null, global);
goog.exportSymbol('proto.sip.people.ScopesOnResource', null, global);
goog.exportSymbol('proto.sip.people.UpdateGroupRequest', null, global);
goog.exportSymbol('proto.sip.people.UpdateUserRequest', null, global);
goog.exportSymbol('proto.sip.people.User', null, global);
goog.exportSymbol('proto.sip.people.User.Filter', null, global);
goog.exportSymbol('proto.sip.people.User.Filter.SortBy', null, global);
goog.exportSymbol('proto.sip.people.User.View', null, global);
goog.exportSymbol('proto.sip.people.VsethMembership', null, global);
goog.exportSymbol('proto.sip.people.VsethMembershipInfo', null, global);
goog.exportSymbol('proto.sip.people.VsethMembershipType', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sip.people.ScopesOnResource = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.sip.people.ScopesOnResource.repeatedFields_, null);
};
goog.inherits(proto.sip.people.ScopesOnResource, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sip.people.ScopesOnResource.displayName = 'proto.sip.people.ScopesOnResource';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sip.people.AuthorizationRole = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.sip.people.AuthorizationRole.repeatedFields_, null);
};
goog.inherits(proto.sip.people.AuthorizationRole, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sip.people.AuthorizationRole.displayName = 'proto.sip.people.AuthorizationRole';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sip.people.ListAuthorizationRolesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.sip.people.ListAuthorizationRolesResponse.repeatedFields_, null);
};
goog.inherits(proto.sip.people.ListAuthorizationRolesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sip.people.ListAuthorizationRolesResponse.displayName = 'proto.sip.people.ListAuthorizationRolesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sip.people.Group = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.sip.people.Group.repeatedFields_, null);
};
goog.inherits(proto.sip.people.Group, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sip.people.Group.displayName = 'proto.sip.people.Group';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sip.people.Group.Filter = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.sip.people.Group.Filter.repeatedFields_, null);
};
goog.inherits(proto.sip.people.Group.Filter, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sip.people.Group.Filter.displayName = 'proto.sip.people.Group.Filter';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sip.people.ListGroupsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sip.people.ListGroupsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sip.people.ListGroupsRequest.displayName = 'proto.sip.people.ListGroupsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sip.people.ListGroupsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.sip.people.ListGroupsResponse.repeatedFields_, null);
};
goog.inherits(proto.sip.people.ListGroupsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sip.people.ListGroupsResponse.displayName = 'proto.sip.people.ListGroupsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sip.people.GetGroupRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sip.people.GetGroupRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sip.people.GetGroupRequest.displayName = 'proto.sip.people.GetGroupRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sip.people.BatchGetGroupRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.sip.people.BatchGetGroupRequest.repeatedFields_, null);
};
goog.inherits(proto.sip.people.BatchGetGroupRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sip.people.BatchGetGroupRequest.displayName = 'proto.sip.people.BatchGetGroupRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sip.people.BatchGetGroupResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.sip.people.BatchGetGroupResponse.repeatedFields_, null);
};
goog.inherits(proto.sip.people.BatchGetGroupResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sip.people.BatchGetGroupResponse.displayName = 'proto.sip.people.BatchGetGroupResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sip.people.CreateGroupRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sip.people.CreateGroupRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sip.people.CreateGroupRequest.displayName = 'proto.sip.people.CreateGroupRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sip.people.UpdateGroupRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sip.people.UpdateGroupRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sip.people.UpdateGroupRequest.displayName = 'proto.sip.people.UpdateGroupRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sip.people.DeleteGroupRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sip.people.DeleteGroupRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sip.people.DeleteGroupRequest.displayName = 'proto.sip.people.DeleteGroupRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sip.people.ChangeGroupMembersRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.sip.people.ChangeGroupMembersRequest.repeatedFields_, null);
};
goog.inherits(proto.sip.people.ChangeGroupMembersRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sip.people.ChangeGroupMembersRequest.displayName = 'proto.sip.people.ChangeGroupMembersRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sip.people.PostalAddress = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sip.people.PostalAddress, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sip.people.PostalAddress.displayName = 'proto.sip.people.PostalAddress';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sip.people.EmploymentInformation = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sip.people.EmploymentInformation, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sip.people.EmploymentInformation.displayName = 'proto.sip.people.EmploymentInformation';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sip.people.BankAccount = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sip.people.BankAccount, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sip.people.BankAccount.displayName = 'proto.sip.people.BankAccount';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sip.people.VsethMembershipInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sip.people.VsethMembershipInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sip.people.VsethMembershipInfo.displayName = 'proto.sip.people.VsethMembershipInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sip.people.EthStudyInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sip.people.EthStudyInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sip.people.EthStudyInfo.displayName = 'proto.sip.people.EthStudyInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sip.people.User = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.sip.people.User.repeatedFields_, null);
};
goog.inherits(proto.sip.people.User, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sip.people.User.displayName = 'proto.sip.people.User';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sip.people.User.Filter = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.sip.people.User.Filter.repeatedFields_, null);
};
goog.inherits(proto.sip.people.User.Filter, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sip.people.User.Filter.displayName = 'proto.sip.people.User.Filter';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sip.people.ListUsersRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sip.people.ListUsersRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sip.people.ListUsersRequest.displayName = 'proto.sip.people.ListUsersRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sip.people.ListUsersResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.sip.people.ListUsersResponse.repeatedFields_, null);
};
goog.inherits(proto.sip.people.ListUsersResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sip.people.ListUsersResponse.displayName = 'proto.sip.people.ListUsersResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sip.people.GetUserRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sip.people.GetUserRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sip.people.GetUserRequest.displayName = 'proto.sip.people.GetUserRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sip.people.BatchGetUserRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.sip.people.BatchGetUserRequest.repeatedFields_, null);
};
goog.inherits(proto.sip.people.BatchGetUserRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sip.people.BatchGetUserRequest.displayName = 'proto.sip.people.BatchGetUserRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sip.people.BatchGetUserResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.sip.people.BatchGetUserResponse.repeatedFields_, null);
};
goog.inherits(proto.sip.people.BatchGetUserResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sip.people.BatchGetUserResponse.displayName = 'proto.sip.people.BatchGetUserResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sip.people.CreateUserRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sip.people.CreateUserRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sip.people.CreateUserRequest.displayName = 'proto.sip.people.CreateUserRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sip.people.UpdateUserRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sip.people.UpdateUserRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sip.people.UpdateUserRequest.displayName = 'proto.sip.people.UpdateUserRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sip.people.DeleteUserRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sip.people.DeleteUserRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sip.people.DeleteUserRequest.displayName = 'proto.sip.people.DeleteUserRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sip.people.AuditLogEntry = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sip.people.AuditLogEntry, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sip.people.AuditLogEntry.displayName = 'proto.sip.people.AuditLogEntry';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sip.people.AuditLogEntry.Filter = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sip.people.AuditLogEntry.Filter, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sip.people.AuditLogEntry.Filter.displayName = 'proto.sip.people.AuditLogEntry.Filter';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sip.people.ListAuditLogEntriesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sip.people.ListAuditLogEntriesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sip.people.ListAuditLogEntriesRequest.displayName = 'proto.sip.people.ListAuditLogEntriesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sip.people.ListAuditLogEntriesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.sip.people.ListAuditLogEntriesResponse.repeatedFields_, null);
};
goog.inherits(proto.sip.people.ListAuditLogEntriesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sip.people.ListAuditLogEntriesResponse.displayName = 'proto.sip.people.ListAuditLogEntriesResponse';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.sip.people.ScopesOnResource.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sip.people.ScopesOnResource.prototype.toObject = function(opt_includeInstance) {
  return proto.sip.people.ScopesOnResource.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sip.people.ScopesOnResource} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.people.ScopesOnResource.toObject = function(includeInstance, msg) {
  var f, obj = {
    resourceName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    inherit: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    scopesList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
    denyPermission: jspb.Message.getBooleanFieldWithDefault(msg, 4, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sip.people.ScopesOnResource}
 */
proto.sip.people.ScopesOnResource.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sip.people.ScopesOnResource;
  return proto.sip.people.ScopesOnResource.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sip.people.ScopesOnResource} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sip.people.ScopesOnResource}
 */
proto.sip.people.ScopesOnResource.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setResourceName(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setInherit(value);
      break;
    case 3:
      var values = /** @type {!Array<!proto.sip.people.ResourceScope>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addScopes(values[i]);
      }
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDenyPermission(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sip.people.ScopesOnResource.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sip.people.ScopesOnResource.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sip.people.ScopesOnResource} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.people.ScopesOnResource.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResourceName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getInherit();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getScopesList();
  if (f.length > 0) {
    writer.writePackedEnum(
      3,
      f
    );
  }
  f = message.getDenyPermission();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
};


/**
 * optional string resource_name = 1;
 * @return {string}
 */
proto.sip.people.ScopesOnResource.prototype.getResourceName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.sip.people.ScopesOnResource} returns this
 */
proto.sip.people.ScopesOnResource.prototype.setResourceName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bool inherit = 2;
 * @return {boolean}
 */
proto.sip.people.ScopesOnResource.prototype.getInherit = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.sip.people.ScopesOnResource} returns this
 */
proto.sip.people.ScopesOnResource.prototype.setInherit = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * repeated ResourceScope scopes = 3;
 * @return {!Array<!proto.sip.people.ResourceScope>}
 */
proto.sip.people.ScopesOnResource.prototype.getScopesList = function() {
  return /** @type {!Array<!proto.sip.people.ResourceScope>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<!proto.sip.people.ResourceScope>} value
 * @return {!proto.sip.people.ScopesOnResource} returns this
 */
proto.sip.people.ScopesOnResource.prototype.setScopesList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {!proto.sip.people.ResourceScope} value
 * @param {number=} opt_index
 * @return {!proto.sip.people.ScopesOnResource} returns this
 */
proto.sip.people.ScopesOnResource.prototype.addScopes = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sip.people.ScopesOnResource} returns this
 */
proto.sip.people.ScopesOnResource.prototype.clearScopesList = function() {
  return this.setScopesList([]);
};


/**
 * optional bool deny_permission = 4;
 * @return {boolean}
 */
proto.sip.people.ScopesOnResource.prototype.getDenyPermission = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.sip.people.ScopesOnResource} returns this
 */
proto.sip.people.ScopesOnResource.prototype.setDenyPermission = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.sip.people.AuthorizationRole.repeatedFields_ = [3,4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sip.people.AuthorizationRole.prototype.toObject = function(opt_includeInstance) {
  return proto.sip.people.AuthorizationRole.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sip.people.AuthorizationRole} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.people.AuthorizationRole.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    displayName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    globalScopesList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
    scopesOnResourceList: jspb.Message.toObjectList(msg.getScopesOnResourceList(),
    proto.sip.people.ScopesOnResource.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sip.people.AuthorizationRole}
 */
proto.sip.people.AuthorizationRole.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sip.people.AuthorizationRole;
  return proto.sip.people.AuthorizationRole.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sip.people.AuthorizationRole} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sip.people.AuthorizationRole}
 */
proto.sip.people.AuthorizationRole.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDisplayName(value);
      break;
    case 3:
      var values = /** @type {!Array<!proto.sip.people.GlobalScope>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addGlobalScopes(values[i]);
      }
      break;
    case 4:
      var value = new proto.sip.people.ScopesOnResource;
      reader.readMessage(value,proto.sip.people.ScopesOnResource.deserializeBinaryFromReader);
      msg.addScopesOnResource(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sip.people.AuthorizationRole.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sip.people.AuthorizationRole.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sip.people.AuthorizationRole} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.people.AuthorizationRole.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDisplayName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getGlobalScopesList();
  if (f.length > 0) {
    writer.writePackedEnum(
      3,
      f
    );
  }
  f = message.getScopesOnResourceList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.sip.people.ScopesOnResource.serializeBinaryToWriter
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.sip.people.AuthorizationRole.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.sip.people.AuthorizationRole} returns this
 */
proto.sip.people.AuthorizationRole.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string display_name = 2;
 * @return {string}
 */
proto.sip.people.AuthorizationRole.prototype.getDisplayName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.sip.people.AuthorizationRole} returns this
 */
proto.sip.people.AuthorizationRole.prototype.setDisplayName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated GlobalScope global_scopes = 3;
 * @return {!Array<!proto.sip.people.GlobalScope>}
 */
proto.sip.people.AuthorizationRole.prototype.getGlobalScopesList = function() {
  return /** @type {!Array<!proto.sip.people.GlobalScope>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<!proto.sip.people.GlobalScope>} value
 * @return {!proto.sip.people.AuthorizationRole} returns this
 */
proto.sip.people.AuthorizationRole.prototype.setGlobalScopesList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {!proto.sip.people.GlobalScope} value
 * @param {number=} opt_index
 * @return {!proto.sip.people.AuthorizationRole} returns this
 */
proto.sip.people.AuthorizationRole.prototype.addGlobalScopes = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sip.people.AuthorizationRole} returns this
 */
proto.sip.people.AuthorizationRole.prototype.clearGlobalScopesList = function() {
  return this.setGlobalScopesList([]);
};


/**
 * repeated ScopesOnResource scopes_on_resource = 4;
 * @return {!Array<!proto.sip.people.ScopesOnResource>}
 */
proto.sip.people.AuthorizationRole.prototype.getScopesOnResourceList = function() {
  return /** @type{!Array<!proto.sip.people.ScopesOnResource>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.sip.people.ScopesOnResource, 4));
};


/**
 * @param {!Array<!proto.sip.people.ScopesOnResource>} value
 * @return {!proto.sip.people.AuthorizationRole} returns this
*/
proto.sip.people.AuthorizationRole.prototype.setScopesOnResourceList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.sip.people.ScopesOnResource=} opt_value
 * @param {number=} opt_index
 * @return {!proto.sip.people.ScopesOnResource}
 */
proto.sip.people.AuthorizationRole.prototype.addScopesOnResource = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.sip.people.ScopesOnResource, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sip.people.AuthorizationRole} returns this
 */
proto.sip.people.AuthorizationRole.prototype.clearScopesOnResourceList = function() {
  return this.setScopesOnResourceList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.sip.people.ListAuthorizationRolesResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sip.people.ListAuthorizationRolesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.sip.people.ListAuthorizationRolesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sip.people.ListAuthorizationRolesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.people.ListAuthorizationRolesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    rolesList: jspb.Message.toObjectList(msg.getRolesList(),
    proto.sip.people.AuthorizationRole.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sip.people.ListAuthorizationRolesResponse}
 */
proto.sip.people.ListAuthorizationRolesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sip.people.ListAuthorizationRolesResponse;
  return proto.sip.people.ListAuthorizationRolesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sip.people.ListAuthorizationRolesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sip.people.ListAuthorizationRolesResponse}
 */
proto.sip.people.ListAuthorizationRolesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.sip.people.AuthorizationRole;
      reader.readMessage(value,proto.sip.people.AuthorizationRole.deserializeBinaryFromReader);
      msg.addRoles(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sip.people.ListAuthorizationRolesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sip.people.ListAuthorizationRolesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sip.people.ListAuthorizationRolesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.people.ListAuthorizationRolesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRolesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.sip.people.AuthorizationRole.serializeBinaryToWriter
    );
  }
};


/**
 * repeated AuthorizationRole roles = 1;
 * @return {!Array<!proto.sip.people.AuthorizationRole>}
 */
proto.sip.people.ListAuthorizationRolesResponse.prototype.getRolesList = function() {
  return /** @type{!Array<!proto.sip.people.AuthorizationRole>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.sip.people.AuthorizationRole, 1));
};


/**
 * @param {!Array<!proto.sip.people.AuthorizationRole>} value
 * @return {!proto.sip.people.ListAuthorizationRolesResponse} returns this
*/
proto.sip.people.ListAuthorizationRolesResponse.prototype.setRolesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.sip.people.AuthorizationRole=} opt_value
 * @param {number=} opt_index
 * @return {!proto.sip.people.AuthorizationRole}
 */
proto.sip.people.ListAuthorizationRolesResponse.prototype.addRoles = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.sip.people.AuthorizationRole, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sip.people.ListAuthorizationRolesResponse} returns this
 */
proto.sip.people.ListAuthorizationRolesResponse.prototype.clearRolesList = function() {
  return this.setRolesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.sip.people.Group.repeatedFields_ = [21,5,6,7,22];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sip.people.Group.prototype.toObject = function(opt_includeInstance) {
  return proto.sip.people.Group.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sip.people.Group} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.people.Group.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    parentName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    parentNamesTransitiveList: (f = jspb.Message.getRepeatedField(msg, 21)) == null ? undefined : f,
    orgId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    objectType: jspb.Message.getFieldWithDefault(msg, 4, 0),
    membersList: (f = jspb.Message.getRepeatedField(msg, 5)) == null ? undefined : f,
    membersTransitiveList: (f = jspb.Message.getRepeatedField(msg, 6)) == null ? undefined : f,
    subGroupsList: (f = jspb.Message.getRepeatedField(msg, 7)) == null ? undefined : f,
    subGroupsTransitiveList: (f = jspb.Message.getRepeatedField(msg, 22)) == null ? undefined : f,
    createTime: (f = msg.getCreateTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    updateTime: (f = msg.getUpdateTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    description: jspb.Message.getFieldWithDefault(msg, 10, ""),
    displayName: jspb.Message.getFieldWithDefault(msg, 11, ""),
    titleDe: jspb.Message.getFieldWithDefault(msg, 12, ""),
    titleEn: jspb.Message.getFieldWithDefault(msg, 13, ""),
    email: jspb.Message.getFieldWithDefault(msg, 14, ""),
    postalAddress: (f = msg.getPostalAddress()) && proto.sip.people.PostalAddress.toObject(includeInstance, f),
    ethOffice: jspb.Message.getFieldWithDefault(msg, 16, ""),
    website: jspb.Message.getFieldWithDefault(msg, 17, ""),
    telephone: jspb.Message.getFieldWithDefault(msg, 18, ""),
    ethOrgId: jspb.Message.getFieldWithDefault(msg, 19, ""),
    leadingIdentifier: jspb.Message.getFieldWithDefault(msg, 20, ""),
    view: jspb.Message.getFieldWithDefault(msg, 23, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sip.people.Group}
 */
proto.sip.people.Group.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sip.people.Group;
  return proto.sip.people.Group.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sip.people.Group} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sip.people.Group}
 */
proto.sip.people.Group.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setParentName(value);
      break;
    case 21:
      var value = /** @type {string} */ (reader.readString());
      msg.addParentNamesTransitive(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrgId(value);
      break;
    case 4:
      var value = /** @type {!proto.sip.people.GroupObjectType} */ (reader.readEnum());
      msg.setObjectType(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.addMembers(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.addMembersTransitive(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.addSubGroups(value);
      break;
    case 22:
      var value = /** @type {string} */ (reader.readString());
      msg.addSubGroupsTransitive(value);
      break;
    case 8:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreateTime(value);
      break;
    case 9:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdateTime(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setDisplayName(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitleDe(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitleEn(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 15:
      var value = new proto.sip.people.PostalAddress;
      reader.readMessage(value,proto.sip.people.PostalAddress.deserializeBinaryFromReader);
      msg.setPostalAddress(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setEthOffice(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setWebsite(value);
      break;
    case 18:
      var value = /** @type {string} */ (reader.readString());
      msg.setTelephone(value);
      break;
    case 19:
      var value = /** @type {string} */ (reader.readString());
      msg.setEthOrgId(value);
      break;
    case 20:
      var value = /** @type {string} */ (reader.readString());
      msg.setLeadingIdentifier(value);
      break;
    case 23:
      var value = /** @type {!proto.sip.people.Group.View} */ (reader.readEnum());
      msg.setView(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sip.people.Group.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sip.people.Group.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sip.people.Group} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.people.Group.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getParentName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getParentNamesTransitiveList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      21,
      f
    );
  }
  f = message.getOrgId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getObjectType();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getMembersList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      5,
      f
    );
  }
  f = message.getMembersTransitiveList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      6,
      f
    );
  }
  f = message.getSubGroupsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      7,
      f
    );
  }
  f = message.getSubGroupsTransitiveList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      22,
      f
    );
  }
  f = message.getCreateTime();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getUpdateTime();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getDisplayName();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getTitleDe();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getTitleEn();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getPostalAddress();
  if (f != null) {
    writer.writeMessage(
      15,
      f,
      proto.sip.people.PostalAddress.serializeBinaryToWriter
    );
  }
  f = message.getEthOffice();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
  f = message.getWebsite();
  if (f.length > 0) {
    writer.writeString(
      17,
      f
    );
  }
  f = message.getTelephone();
  if (f.length > 0) {
    writer.writeString(
      18,
      f
    );
  }
  f = message.getEthOrgId();
  if (f.length > 0) {
    writer.writeString(
      19,
      f
    );
  }
  f = message.getLeadingIdentifier();
  if (f.length > 0) {
    writer.writeString(
      20,
      f
    );
  }
  f = message.getView();
  if (f !== 0.0) {
    writer.writeEnum(
      23,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.sip.people.Group.View = {
  VIEW_UNSPECIFIED: 0,
  BASIC: 1,
  INCLUDE_SUBGROUPS: 2,
  INCLUDE_MEMBERS: 3,
  KEYCLOAK: 4
};


/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.sip.people.Group.Filter.repeatedFields_ = [21,5,7];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sip.people.Group.Filter.prototype.toObject = function(opt_includeInstance) {
  return proto.sip.people.Group.Filter.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sip.people.Group.Filter} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.people.Group.Filter.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    orgId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    parentNamesTransitiveList: (f = jspb.Message.getRepeatedField(msg, 21)) == null ? undefined : f,
    objectType: jspb.Message.getFieldWithDefault(msg, 4, 0),
    membersList: (f = jspb.Message.getRepeatedField(msg, 5)) == null ? undefined : f,
    subGroupsList: (f = jspb.Message.getRepeatedField(msg, 7)) == null ? undefined : f,
    createTime: (f = msg.getCreateTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    updateTime: (f = msg.getUpdateTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    description: jspb.Message.getFieldWithDefault(msg, 10, ""),
    displayName: jspb.Message.getFieldWithDefault(msg, 11, ""),
    titleDe: jspb.Message.getFieldWithDefault(msg, 12, ""),
    titleEn: jspb.Message.getFieldWithDefault(msg, 13, ""),
    email: jspb.Message.getFieldWithDefault(msg, 14, ""),
    ethOffice: jspb.Message.getFieldWithDefault(msg, 16, ""),
    website: jspb.Message.getFieldWithDefault(msg, 17, ""),
    telephone: jspb.Message.getFieldWithDefault(msg, 18, ""),
    ethOrgId: jspb.Message.getFieldWithDefault(msg, 19, ""),
    withTags: jspb.Message.getBooleanFieldWithDefault(msg, 40, false),
    sortBy: jspb.Message.getFieldWithDefault(msg, 30, 0),
    descending: jspb.Message.getBooleanFieldWithDefault(msg, 31, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sip.people.Group.Filter}
 */
proto.sip.people.Group.Filter.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sip.people.Group.Filter;
  return proto.sip.people.Group.Filter.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sip.people.Group.Filter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sip.people.Group.Filter}
 */
proto.sip.people.Group.Filter.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrgId(value);
      break;
    case 21:
      var value = /** @type {string} */ (reader.readString());
      msg.addParentNamesTransitive(value);
      break;
    case 4:
      var value = /** @type {!proto.sip.people.GroupObjectType} */ (reader.readEnum());
      msg.setObjectType(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.addMembers(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.addSubGroups(value);
      break;
    case 8:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreateTime(value);
      break;
    case 9:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdateTime(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setDisplayName(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitleDe(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitleEn(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setEthOffice(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setWebsite(value);
      break;
    case 18:
      var value = /** @type {string} */ (reader.readString());
      msg.setTelephone(value);
      break;
    case 19:
      var value = /** @type {string} */ (reader.readString());
      msg.setEthOrgId(value);
      break;
    case 40:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setWithTags(value);
      break;
    case 30:
      var value = /** @type {!proto.sip.people.Group.Filter.SortBy} */ (reader.readEnum());
      msg.setSortBy(value);
      break;
    case 31:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDescending(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sip.people.Group.Filter.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sip.people.Group.Filter.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sip.people.Group.Filter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.people.Group.Filter.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOrgId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getParentNamesTransitiveList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      21,
      f
    );
  }
  f = message.getObjectType();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getMembersList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      5,
      f
    );
  }
  f = message.getSubGroupsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      7,
      f
    );
  }
  f = message.getCreateTime();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getUpdateTime();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getDisplayName();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getTitleDe();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getTitleEn();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getEthOffice();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
  f = message.getWebsite();
  if (f.length > 0) {
    writer.writeString(
      17,
      f
    );
  }
  f = message.getTelephone();
  if (f.length > 0) {
    writer.writeString(
      18,
      f
    );
  }
  f = message.getEthOrgId();
  if (f.length > 0) {
    writer.writeString(
      19,
      f
    );
  }
  f = message.getWithTags();
  if (f) {
    writer.writeBool(
      40,
      f
    );
  }
  f = message.getSortBy();
  if (f !== 0.0) {
    writer.writeEnum(
      30,
      f
    );
  }
  f = message.getDescending();
  if (f) {
    writer.writeBool(
      31,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.sip.people.Group.Filter.SortBy = {
  NAME: 0,
  PARENT_NAME: 1,
  ORG_ID: 2,
  OBJECT_TYPE: 3,
  CREATE_TIME: 4,
  UPDATE_TIME: 5,
  DESCRIPTION: 6,
  DISPLAY_NAME: 7,
  TITLE_DE: 8,
  TITLE_EN: 9,
  EMAIL: 10,
  ETH_OFFICE: 11,
  WEBSITE: 12,
  TELEPHONE: 13,
  ETH_ORG_ID: 14
};

/**
 * optional string name = 1;
 * @return {string}
 */
proto.sip.people.Group.Filter.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.sip.people.Group.Filter} returns this
 */
proto.sip.people.Group.Filter.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string org_id = 3;
 * @return {string}
 */
proto.sip.people.Group.Filter.prototype.getOrgId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.sip.people.Group.Filter} returns this
 */
proto.sip.people.Group.Filter.prototype.setOrgId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated string parent_names_transitive = 21;
 * @return {!Array<string>}
 */
proto.sip.people.Group.Filter.prototype.getParentNamesTransitiveList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 21));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.sip.people.Group.Filter} returns this
 */
proto.sip.people.Group.Filter.prototype.setParentNamesTransitiveList = function(value) {
  return jspb.Message.setField(this, 21, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.sip.people.Group.Filter} returns this
 */
proto.sip.people.Group.Filter.prototype.addParentNamesTransitive = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 21, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sip.people.Group.Filter} returns this
 */
proto.sip.people.Group.Filter.prototype.clearParentNamesTransitiveList = function() {
  return this.setParentNamesTransitiveList([]);
};


/**
 * optional GroupObjectType object_type = 4;
 * @return {!proto.sip.people.GroupObjectType}
 */
proto.sip.people.Group.Filter.prototype.getObjectType = function() {
  return /** @type {!proto.sip.people.GroupObjectType} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.sip.people.GroupObjectType} value
 * @return {!proto.sip.people.Group.Filter} returns this
 */
proto.sip.people.Group.Filter.prototype.setObjectType = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * repeated string members = 5;
 * @return {!Array<string>}
 */
proto.sip.people.Group.Filter.prototype.getMembersList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 5));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.sip.people.Group.Filter} returns this
 */
proto.sip.people.Group.Filter.prototype.setMembersList = function(value) {
  return jspb.Message.setField(this, 5, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.sip.people.Group.Filter} returns this
 */
proto.sip.people.Group.Filter.prototype.addMembers = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 5, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sip.people.Group.Filter} returns this
 */
proto.sip.people.Group.Filter.prototype.clearMembersList = function() {
  return this.setMembersList([]);
};


/**
 * repeated string sub_groups = 7;
 * @return {!Array<string>}
 */
proto.sip.people.Group.Filter.prototype.getSubGroupsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 7));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.sip.people.Group.Filter} returns this
 */
proto.sip.people.Group.Filter.prototype.setSubGroupsList = function(value) {
  return jspb.Message.setField(this, 7, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.sip.people.Group.Filter} returns this
 */
proto.sip.people.Group.Filter.prototype.addSubGroups = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 7, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sip.people.Group.Filter} returns this
 */
proto.sip.people.Group.Filter.prototype.clearSubGroupsList = function() {
  return this.setSubGroupsList([]);
};


/**
 * optional google.protobuf.Timestamp create_time = 8;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.sip.people.Group.Filter.prototype.getCreateTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 8));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.sip.people.Group.Filter} returns this
*/
proto.sip.people.Group.Filter.prototype.setCreateTime = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sip.people.Group.Filter} returns this
 */
proto.sip.people.Group.Filter.prototype.clearCreateTime = function() {
  return this.setCreateTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sip.people.Group.Filter.prototype.hasCreateTime = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional google.protobuf.Timestamp update_time = 9;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.sip.people.Group.Filter.prototype.getUpdateTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 9));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.sip.people.Group.Filter} returns this
*/
proto.sip.people.Group.Filter.prototype.setUpdateTime = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sip.people.Group.Filter} returns this
 */
proto.sip.people.Group.Filter.prototype.clearUpdateTime = function() {
  return this.setUpdateTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sip.people.Group.Filter.prototype.hasUpdateTime = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional string description = 10;
 * @return {string}
 */
proto.sip.people.Group.Filter.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.sip.people.Group.Filter} returns this
 */
proto.sip.people.Group.Filter.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string display_name = 11;
 * @return {string}
 */
proto.sip.people.Group.Filter.prototype.getDisplayName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.sip.people.Group.Filter} returns this
 */
proto.sip.people.Group.Filter.prototype.setDisplayName = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string title_de = 12;
 * @return {string}
 */
proto.sip.people.Group.Filter.prototype.getTitleDe = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.sip.people.Group.Filter} returns this
 */
proto.sip.people.Group.Filter.prototype.setTitleDe = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string title_en = 13;
 * @return {string}
 */
proto.sip.people.Group.Filter.prototype.getTitleEn = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.sip.people.Group.Filter} returns this
 */
proto.sip.people.Group.Filter.prototype.setTitleEn = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional string email = 14;
 * @return {string}
 */
proto.sip.people.Group.Filter.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.sip.people.Group.Filter} returns this
 */
proto.sip.people.Group.Filter.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional string eth_office = 16;
 * @return {string}
 */
proto.sip.people.Group.Filter.prototype.getEthOffice = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.sip.people.Group.Filter} returns this
 */
proto.sip.people.Group.Filter.prototype.setEthOffice = function(value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};


/**
 * optional string website = 17;
 * @return {string}
 */
proto.sip.people.Group.Filter.prototype.getWebsite = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * @param {string} value
 * @return {!proto.sip.people.Group.Filter} returns this
 */
proto.sip.people.Group.Filter.prototype.setWebsite = function(value) {
  return jspb.Message.setProto3StringField(this, 17, value);
};


/**
 * optional string telephone = 18;
 * @return {string}
 */
proto.sip.people.Group.Filter.prototype.getTelephone = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 18, ""));
};


/**
 * @param {string} value
 * @return {!proto.sip.people.Group.Filter} returns this
 */
proto.sip.people.Group.Filter.prototype.setTelephone = function(value) {
  return jspb.Message.setProto3StringField(this, 18, value);
};


/**
 * optional string eth_org_id = 19;
 * @return {string}
 */
proto.sip.people.Group.Filter.prototype.getEthOrgId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 19, ""));
};


/**
 * @param {string} value
 * @return {!proto.sip.people.Group.Filter} returns this
 */
proto.sip.people.Group.Filter.prototype.setEthOrgId = function(value) {
  return jspb.Message.setProto3StringField(this, 19, value);
};


/**
 * optional bool with_tags = 40;
 * @return {boolean}
 */
proto.sip.people.Group.Filter.prototype.getWithTags = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 40, false));
};


/**
 * @param {boolean} value
 * @return {!proto.sip.people.Group.Filter} returns this
 */
proto.sip.people.Group.Filter.prototype.setWithTags = function(value) {
  return jspb.Message.setProto3BooleanField(this, 40, value);
};


/**
 * optional SortBy sort_by = 30;
 * @return {!proto.sip.people.Group.Filter.SortBy}
 */
proto.sip.people.Group.Filter.prototype.getSortBy = function() {
  return /** @type {!proto.sip.people.Group.Filter.SortBy} */ (jspb.Message.getFieldWithDefault(this, 30, 0));
};


/**
 * @param {!proto.sip.people.Group.Filter.SortBy} value
 * @return {!proto.sip.people.Group.Filter} returns this
 */
proto.sip.people.Group.Filter.prototype.setSortBy = function(value) {
  return jspb.Message.setProto3EnumField(this, 30, value);
};


/**
 * optional bool descending = 31;
 * @return {boolean}
 */
proto.sip.people.Group.Filter.prototype.getDescending = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 31, false));
};


/**
 * @param {boolean} value
 * @return {!proto.sip.people.Group.Filter} returns this
 */
proto.sip.people.Group.Filter.prototype.setDescending = function(value) {
  return jspb.Message.setProto3BooleanField(this, 31, value);
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.sip.people.Group.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.sip.people.Group} returns this
 */
proto.sip.people.Group.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string parent_name = 2;
 * @return {string}
 */
proto.sip.people.Group.prototype.getParentName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.sip.people.Group} returns this
 */
proto.sip.people.Group.prototype.setParentName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated string parent_names_transitive = 21;
 * @return {!Array<string>}
 */
proto.sip.people.Group.prototype.getParentNamesTransitiveList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 21));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.sip.people.Group} returns this
 */
proto.sip.people.Group.prototype.setParentNamesTransitiveList = function(value) {
  return jspb.Message.setField(this, 21, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.sip.people.Group} returns this
 */
proto.sip.people.Group.prototype.addParentNamesTransitive = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 21, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sip.people.Group} returns this
 */
proto.sip.people.Group.prototype.clearParentNamesTransitiveList = function() {
  return this.setParentNamesTransitiveList([]);
};


/**
 * optional string org_id = 3;
 * @return {string}
 */
proto.sip.people.Group.prototype.getOrgId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.sip.people.Group} returns this
 */
proto.sip.people.Group.prototype.setOrgId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional GroupObjectType object_type = 4;
 * @return {!proto.sip.people.GroupObjectType}
 */
proto.sip.people.Group.prototype.getObjectType = function() {
  return /** @type {!proto.sip.people.GroupObjectType} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.sip.people.GroupObjectType} value
 * @return {!proto.sip.people.Group} returns this
 */
proto.sip.people.Group.prototype.setObjectType = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * repeated string members = 5;
 * @return {!Array<string>}
 */
proto.sip.people.Group.prototype.getMembersList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 5));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.sip.people.Group} returns this
 */
proto.sip.people.Group.prototype.setMembersList = function(value) {
  return jspb.Message.setField(this, 5, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.sip.people.Group} returns this
 */
proto.sip.people.Group.prototype.addMembers = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 5, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sip.people.Group} returns this
 */
proto.sip.people.Group.prototype.clearMembersList = function() {
  return this.setMembersList([]);
};


/**
 * repeated string members_transitive = 6;
 * @return {!Array<string>}
 */
proto.sip.people.Group.prototype.getMembersTransitiveList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 6));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.sip.people.Group} returns this
 */
proto.sip.people.Group.prototype.setMembersTransitiveList = function(value) {
  return jspb.Message.setField(this, 6, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.sip.people.Group} returns this
 */
proto.sip.people.Group.prototype.addMembersTransitive = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 6, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sip.people.Group} returns this
 */
proto.sip.people.Group.prototype.clearMembersTransitiveList = function() {
  return this.setMembersTransitiveList([]);
};


/**
 * repeated string sub_groups = 7;
 * @return {!Array<string>}
 */
proto.sip.people.Group.prototype.getSubGroupsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 7));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.sip.people.Group} returns this
 */
proto.sip.people.Group.prototype.setSubGroupsList = function(value) {
  return jspb.Message.setField(this, 7, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.sip.people.Group} returns this
 */
proto.sip.people.Group.prototype.addSubGroups = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 7, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sip.people.Group} returns this
 */
proto.sip.people.Group.prototype.clearSubGroupsList = function() {
  return this.setSubGroupsList([]);
};


/**
 * repeated string sub_groups_transitive = 22;
 * @return {!Array<string>}
 */
proto.sip.people.Group.prototype.getSubGroupsTransitiveList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 22));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.sip.people.Group} returns this
 */
proto.sip.people.Group.prototype.setSubGroupsTransitiveList = function(value) {
  return jspb.Message.setField(this, 22, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.sip.people.Group} returns this
 */
proto.sip.people.Group.prototype.addSubGroupsTransitive = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 22, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sip.people.Group} returns this
 */
proto.sip.people.Group.prototype.clearSubGroupsTransitiveList = function() {
  return this.setSubGroupsTransitiveList([]);
};


/**
 * optional google.protobuf.Timestamp create_time = 8;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.sip.people.Group.prototype.getCreateTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 8));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.sip.people.Group} returns this
*/
proto.sip.people.Group.prototype.setCreateTime = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sip.people.Group} returns this
 */
proto.sip.people.Group.prototype.clearCreateTime = function() {
  return this.setCreateTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sip.people.Group.prototype.hasCreateTime = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional google.protobuf.Timestamp update_time = 9;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.sip.people.Group.prototype.getUpdateTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 9));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.sip.people.Group} returns this
*/
proto.sip.people.Group.prototype.setUpdateTime = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sip.people.Group} returns this
 */
proto.sip.people.Group.prototype.clearUpdateTime = function() {
  return this.setUpdateTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sip.people.Group.prototype.hasUpdateTime = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional string description = 10;
 * @return {string}
 */
proto.sip.people.Group.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.sip.people.Group} returns this
 */
proto.sip.people.Group.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string display_name = 11;
 * @return {string}
 */
proto.sip.people.Group.prototype.getDisplayName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.sip.people.Group} returns this
 */
proto.sip.people.Group.prototype.setDisplayName = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string title_de = 12;
 * @return {string}
 */
proto.sip.people.Group.prototype.getTitleDe = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.sip.people.Group} returns this
 */
proto.sip.people.Group.prototype.setTitleDe = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string title_en = 13;
 * @return {string}
 */
proto.sip.people.Group.prototype.getTitleEn = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.sip.people.Group} returns this
 */
proto.sip.people.Group.prototype.setTitleEn = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional string email = 14;
 * @return {string}
 */
proto.sip.people.Group.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.sip.people.Group} returns this
 */
proto.sip.people.Group.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional PostalAddress postal_address = 15;
 * @return {?proto.sip.people.PostalAddress}
 */
proto.sip.people.Group.prototype.getPostalAddress = function() {
  return /** @type{?proto.sip.people.PostalAddress} */ (
    jspb.Message.getWrapperField(this, proto.sip.people.PostalAddress, 15));
};


/**
 * @param {?proto.sip.people.PostalAddress|undefined} value
 * @return {!proto.sip.people.Group} returns this
*/
proto.sip.people.Group.prototype.setPostalAddress = function(value) {
  return jspb.Message.setWrapperField(this, 15, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sip.people.Group} returns this
 */
proto.sip.people.Group.prototype.clearPostalAddress = function() {
  return this.setPostalAddress(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sip.people.Group.prototype.hasPostalAddress = function() {
  return jspb.Message.getField(this, 15) != null;
};


/**
 * optional string eth_office = 16;
 * @return {string}
 */
proto.sip.people.Group.prototype.getEthOffice = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.sip.people.Group} returns this
 */
proto.sip.people.Group.prototype.setEthOffice = function(value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};


/**
 * optional string website = 17;
 * @return {string}
 */
proto.sip.people.Group.prototype.getWebsite = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * @param {string} value
 * @return {!proto.sip.people.Group} returns this
 */
proto.sip.people.Group.prototype.setWebsite = function(value) {
  return jspb.Message.setProto3StringField(this, 17, value);
};


/**
 * optional string telephone = 18;
 * @return {string}
 */
proto.sip.people.Group.prototype.getTelephone = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 18, ""));
};


/**
 * @param {string} value
 * @return {!proto.sip.people.Group} returns this
 */
proto.sip.people.Group.prototype.setTelephone = function(value) {
  return jspb.Message.setProto3StringField(this, 18, value);
};


/**
 * optional string eth_org_id = 19;
 * @return {string}
 */
proto.sip.people.Group.prototype.getEthOrgId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 19, ""));
};


/**
 * @param {string} value
 * @return {!proto.sip.people.Group} returns this
 */
proto.sip.people.Group.prototype.setEthOrgId = function(value) {
  return jspb.Message.setProto3StringField(this, 19, value);
};


/**
 * optional string leading_identifier = 20;
 * @return {string}
 */
proto.sip.people.Group.prototype.getLeadingIdentifier = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 20, ""));
};


/**
 * @param {string} value
 * @return {!proto.sip.people.Group} returns this
 */
proto.sip.people.Group.prototype.setLeadingIdentifier = function(value) {
  return jspb.Message.setProto3StringField(this, 20, value);
};


/**
 * optional View view = 23;
 * @return {!proto.sip.people.Group.View}
 */
proto.sip.people.Group.prototype.getView = function() {
  return /** @type {!proto.sip.people.Group.View} */ (jspb.Message.getFieldWithDefault(this, 23, 0));
};


/**
 * @param {!proto.sip.people.Group.View} value
 * @return {!proto.sip.people.Group} returns this
 */
proto.sip.people.Group.prototype.setView = function(value) {
  return jspb.Message.setProto3EnumField(this, 23, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sip.people.ListGroupsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.sip.people.ListGroupsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sip.people.ListGroupsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.people.ListGroupsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    pageSize: jspb.Message.getFieldWithDefault(msg, 1, 0),
    pageToken: jspb.Message.getFieldWithDefault(msg, 2, ""),
    filter: (f = msg.getFilter()) && proto.sip.people.Group.Filter.toObject(includeInstance, f),
    view: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sip.people.ListGroupsRequest}
 */
proto.sip.people.ListGroupsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sip.people.ListGroupsRequest;
  return proto.sip.people.ListGroupsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sip.people.ListGroupsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sip.people.ListGroupsRequest}
 */
proto.sip.people.ListGroupsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPageSize(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPageToken(value);
      break;
    case 3:
      var value = new proto.sip.people.Group.Filter;
      reader.readMessage(value,proto.sip.people.Group.Filter.deserializeBinaryFromReader);
      msg.setFilter(value);
      break;
    case 4:
      var value = /** @type {!proto.sip.people.Group.View} */ (reader.readEnum());
      msg.setView(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sip.people.ListGroupsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sip.people.ListGroupsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sip.people.ListGroupsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.people.ListGroupsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPageSize();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getPageToken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getFilter();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.sip.people.Group.Filter.serializeBinaryToWriter
    );
  }
  f = message.getView();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
};


/**
 * optional int32 page_size = 1;
 * @return {number}
 */
proto.sip.people.ListGroupsRequest.prototype.getPageSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.sip.people.ListGroupsRequest} returns this
 */
proto.sip.people.ListGroupsRequest.prototype.setPageSize = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string page_token = 2;
 * @return {string}
 */
proto.sip.people.ListGroupsRequest.prototype.getPageToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.sip.people.ListGroupsRequest} returns this
 */
proto.sip.people.ListGroupsRequest.prototype.setPageToken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional Group.Filter filter = 3;
 * @return {?proto.sip.people.Group.Filter}
 */
proto.sip.people.ListGroupsRequest.prototype.getFilter = function() {
  return /** @type{?proto.sip.people.Group.Filter} */ (
    jspb.Message.getWrapperField(this, proto.sip.people.Group.Filter, 3));
};


/**
 * @param {?proto.sip.people.Group.Filter|undefined} value
 * @return {!proto.sip.people.ListGroupsRequest} returns this
*/
proto.sip.people.ListGroupsRequest.prototype.setFilter = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sip.people.ListGroupsRequest} returns this
 */
proto.sip.people.ListGroupsRequest.prototype.clearFilter = function() {
  return this.setFilter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sip.people.ListGroupsRequest.prototype.hasFilter = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional Group.View view = 4;
 * @return {!proto.sip.people.Group.View}
 */
proto.sip.people.ListGroupsRequest.prototype.getView = function() {
  return /** @type {!proto.sip.people.Group.View} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.sip.people.Group.View} value
 * @return {!proto.sip.people.ListGroupsRequest} returns this
 */
proto.sip.people.ListGroupsRequest.prototype.setView = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.sip.people.ListGroupsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sip.people.ListGroupsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.sip.people.ListGroupsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sip.people.ListGroupsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.people.ListGroupsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    groupsList: jspb.Message.toObjectList(msg.getGroupsList(),
    proto.sip.people.Group.toObject, includeInstance),
    nextPageToken: jspb.Message.getFieldWithDefault(msg, 2, ""),
    totalSize: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sip.people.ListGroupsResponse}
 */
proto.sip.people.ListGroupsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sip.people.ListGroupsResponse;
  return proto.sip.people.ListGroupsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sip.people.ListGroupsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sip.people.ListGroupsResponse}
 */
proto.sip.people.ListGroupsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.sip.people.Group;
      reader.readMessage(value,proto.sip.people.Group.deserializeBinaryFromReader);
      msg.addGroups(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setNextPageToken(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotalSize(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sip.people.ListGroupsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sip.people.ListGroupsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sip.people.ListGroupsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.people.ListGroupsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGroupsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.sip.people.Group.serializeBinaryToWriter
    );
  }
  f = message.getNextPageToken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTotalSize();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
};


/**
 * repeated Group groups = 1;
 * @return {!Array<!proto.sip.people.Group>}
 */
proto.sip.people.ListGroupsResponse.prototype.getGroupsList = function() {
  return /** @type{!Array<!proto.sip.people.Group>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.sip.people.Group, 1));
};


/**
 * @param {!Array<!proto.sip.people.Group>} value
 * @return {!proto.sip.people.ListGroupsResponse} returns this
*/
proto.sip.people.ListGroupsResponse.prototype.setGroupsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.sip.people.Group=} opt_value
 * @param {number=} opt_index
 * @return {!proto.sip.people.Group}
 */
proto.sip.people.ListGroupsResponse.prototype.addGroups = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.sip.people.Group, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sip.people.ListGroupsResponse} returns this
 */
proto.sip.people.ListGroupsResponse.prototype.clearGroupsList = function() {
  return this.setGroupsList([]);
};


/**
 * optional string next_page_token = 2;
 * @return {string}
 */
proto.sip.people.ListGroupsResponse.prototype.getNextPageToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.sip.people.ListGroupsResponse} returns this
 */
proto.sip.people.ListGroupsResponse.prototype.setNextPageToken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 total_size = 3;
 * @return {number}
 */
proto.sip.people.ListGroupsResponse.prototype.getTotalSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.sip.people.ListGroupsResponse} returns this
 */
proto.sip.people.ListGroupsResponse.prototype.setTotalSize = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sip.people.GetGroupRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.sip.people.GetGroupRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sip.people.GetGroupRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.people.GetGroupRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    view: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sip.people.GetGroupRequest}
 */
proto.sip.people.GetGroupRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sip.people.GetGroupRequest;
  return proto.sip.people.GetGroupRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sip.people.GetGroupRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sip.people.GetGroupRequest}
 */
proto.sip.people.GetGroupRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {!proto.sip.people.Group.View} */ (reader.readEnum());
      msg.setView(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sip.people.GetGroupRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sip.people.GetGroupRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sip.people.GetGroupRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.people.GetGroupRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getView();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.sip.people.GetGroupRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.sip.people.GetGroupRequest} returns this
 */
proto.sip.people.GetGroupRequest.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional Group.View view = 2;
 * @return {!proto.sip.people.Group.View}
 */
proto.sip.people.GetGroupRequest.prototype.getView = function() {
  return /** @type {!proto.sip.people.Group.View} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.sip.people.Group.View} value
 * @return {!proto.sip.people.GetGroupRequest} returns this
 */
proto.sip.people.GetGroupRequest.prototype.setView = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.sip.people.BatchGetGroupRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sip.people.BatchGetGroupRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.sip.people.BatchGetGroupRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sip.people.BatchGetGroupRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.people.BatchGetGroupRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    namesList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    view: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sip.people.BatchGetGroupRequest}
 */
proto.sip.people.BatchGetGroupRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sip.people.BatchGetGroupRequest;
  return proto.sip.people.BatchGetGroupRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sip.people.BatchGetGroupRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sip.people.BatchGetGroupRequest}
 */
proto.sip.people.BatchGetGroupRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addNames(value);
      break;
    case 2:
      var value = /** @type {!proto.sip.people.Group.View} */ (reader.readEnum());
      msg.setView(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sip.people.BatchGetGroupRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sip.people.BatchGetGroupRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sip.people.BatchGetGroupRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.people.BatchGetGroupRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNamesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
  f = message.getView();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * repeated string names = 1;
 * @return {!Array<string>}
 */
proto.sip.people.BatchGetGroupRequest.prototype.getNamesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.sip.people.BatchGetGroupRequest} returns this
 */
proto.sip.people.BatchGetGroupRequest.prototype.setNamesList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.sip.people.BatchGetGroupRequest} returns this
 */
proto.sip.people.BatchGetGroupRequest.prototype.addNames = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sip.people.BatchGetGroupRequest} returns this
 */
proto.sip.people.BatchGetGroupRequest.prototype.clearNamesList = function() {
  return this.setNamesList([]);
};


/**
 * optional Group.View view = 2;
 * @return {!proto.sip.people.Group.View}
 */
proto.sip.people.BatchGetGroupRequest.prototype.getView = function() {
  return /** @type {!proto.sip.people.Group.View} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.sip.people.Group.View} value
 * @return {!proto.sip.people.BatchGetGroupRequest} returns this
 */
proto.sip.people.BatchGetGroupRequest.prototype.setView = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.sip.people.BatchGetGroupResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sip.people.BatchGetGroupResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.sip.people.BatchGetGroupResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sip.people.BatchGetGroupResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.people.BatchGetGroupResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    groupsList: jspb.Message.toObjectList(msg.getGroupsList(),
    proto.sip.people.Group.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sip.people.BatchGetGroupResponse}
 */
proto.sip.people.BatchGetGroupResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sip.people.BatchGetGroupResponse;
  return proto.sip.people.BatchGetGroupResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sip.people.BatchGetGroupResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sip.people.BatchGetGroupResponse}
 */
proto.sip.people.BatchGetGroupResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.sip.people.Group;
      reader.readMessage(value,proto.sip.people.Group.deserializeBinaryFromReader);
      msg.addGroups(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sip.people.BatchGetGroupResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sip.people.BatchGetGroupResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sip.people.BatchGetGroupResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.people.BatchGetGroupResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGroupsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.sip.people.Group.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Group groups = 1;
 * @return {!Array<!proto.sip.people.Group>}
 */
proto.sip.people.BatchGetGroupResponse.prototype.getGroupsList = function() {
  return /** @type{!Array<!proto.sip.people.Group>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.sip.people.Group, 1));
};


/**
 * @param {!Array<!proto.sip.people.Group>} value
 * @return {!proto.sip.people.BatchGetGroupResponse} returns this
*/
proto.sip.people.BatchGetGroupResponse.prototype.setGroupsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.sip.people.Group=} opt_value
 * @param {number=} opt_index
 * @return {!proto.sip.people.Group}
 */
proto.sip.people.BatchGetGroupResponse.prototype.addGroups = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.sip.people.Group, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sip.people.BatchGetGroupResponse} returns this
 */
proto.sip.people.BatchGetGroupResponse.prototype.clearGroupsList = function() {
  return this.setGroupsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sip.people.CreateGroupRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.sip.people.CreateGroupRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sip.people.CreateGroupRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.people.CreateGroupRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    group: (f = msg.getGroup()) && proto.sip.people.Group.toObject(includeInstance, f),
    auditMessage: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sip.people.CreateGroupRequest}
 */
proto.sip.people.CreateGroupRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sip.people.CreateGroupRequest;
  return proto.sip.people.CreateGroupRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sip.people.CreateGroupRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sip.people.CreateGroupRequest}
 */
proto.sip.people.CreateGroupRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.sip.people.Group;
      reader.readMessage(value,proto.sip.people.Group.deserializeBinaryFromReader);
      msg.setGroup(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAuditMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sip.people.CreateGroupRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sip.people.CreateGroupRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sip.people.CreateGroupRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.people.CreateGroupRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGroup();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.sip.people.Group.serializeBinaryToWriter
    );
  }
  f = message.getAuditMessage();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional Group group = 1;
 * @return {?proto.sip.people.Group}
 */
proto.sip.people.CreateGroupRequest.prototype.getGroup = function() {
  return /** @type{?proto.sip.people.Group} */ (
    jspb.Message.getWrapperField(this, proto.sip.people.Group, 1));
};


/**
 * @param {?proto.sip.people.Group|undefined} value
 * @return {!proto.sip.people.CreateGroupRequest} returns this
*/
proto.sip.people.CreateGroupRequest.prototype.setGroup = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sip.people.CreateGroupRequest} returns this
 */
proto.sip.people.CreateGroupRequest.prototype.clearGroup = function() {
  return this.setGroup(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sip.people.CreateGroupRequest.prototype.hasGroup = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string audit_message = 2;
 * @return {string}
 */
proto.sip.people.CreateGroupRequest.prototype.getAuditMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.sip.people.CreateGroupRequest} returns this
 */
proto.sip.people.CreateGroupRequest.prototype.setAuditMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sip.people.UpdateGroupRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.sip.people.UpdateGroupRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sip.people.UpdateGroupRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.people.UpdateGroupRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    auditMessage: jspb.Message.getFieldWithDefault(msg, 2, ""),
    group: (f = msg.getGroup()) && proto.sip.people.Group.toObject(includeInstance, f),
    updateMask: (f = msg.getUpdateMask()) && google_protobuf_field_mask_pb.FieldMask.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sip.people.UpdateGroupRequest}
 */
proto.sip.people.UpdateGroupRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sip.people.UpdateGroupRequest;
  return proto.sip.people.UpdateGroupRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sip.people.UpdateGroupRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sip.people.UpdateGroupRequest}
 */
proto.sip.people.UpdateGroupRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAuditMessage(value);
      break;
    case 3:
      var value = new proto.sip.people.Group;
      reader.readMessage(value,proto.sip.people.Group.deserializeBinaryFromReader);
      msg.setGroup(value);
      break;
    case 4:
      var value = new google_protobuf_field_mask_pb.FieldMask;
      reader.readMessage(value,google_protobuf_field_mask_pb.FieldMask.deserializeBinaryFromReader);
      msg.setUpdateMask(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sip.people.UpdateGroupRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sip.people.UpdateGroupRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sip.people.UpdateGroupRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.people.UpdateGroupRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAuditMessage();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getGroup();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.sip.people.Group.serializeBinaryToWriter
    );
  }
  f = message.getUpdateMask();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_field_mask_pb.FieldMask.serializeBinaryToWriter
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.sip.people.UpdateGroupRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.sip.people.UpdateGroupRequest} returns this
 */
proto.sip.people.UpdateGroupRequest.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string audit_message = 2;
 * @return {string}
 */
proto.sip.people.UpdateGroupRequest.prototype.getAuditMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.sip.people.UpdateGroupRequest} returns this
 */
proto.sip.people.UpdateGroupRequest.prototype.setAuditMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional Group group = 3;
 * @return {?proto.sip.people.Group}
 */
proto.sip.people.UpdateGroupRequest.prototype.getGroup = function() {
  return /** @type{?proto.sip.people.Group} */ (
    jspb.Message.getWrapperField(this, proto.sip.people.Group, 3));
};


/**
 * @param {?proto.sip.people.Group|undefined} value
 * @return {!proto.sip.people.UpdateGroupRequest} returns this
*/
proto.sip.people.UpdateGroupRequest.prototype.setGroup = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sip.people.UpdateGroupRequest} returns this
 */
proto.sip.people.UpdateGroupRequest.prototype.clearGroup = function() {
  return this.setGroup(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sip.people.UpdateGroupRequest.prototype.hasGroup = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.protobuf.FieldMask update_mask = 4;
 * @return {?proto.google.protobuf.FieldMask}
 */
proto.sip.people.UpdateGroupRequest.prototype.getUpdateMask = function() {
  return /** @type{?proto.google.protobuf.FieldMask} */ (
    jspb.Message.getWrapperField(this, google_protobuf_field_mask_pb.FieldMask, 4));
};


/**
 * @param {?proto.google.protobuf.FieldMask|undefined} value
 * @return {!proto.sip.people.UpdateGroupRequest} returns this
*/
proto.sip.people.UpdateGroupRequest.prototype.setUpdateMask = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sip.people.UpdateGroupRequest} returns this
 */
proto.sip.people.UpdateGroupRequest.prototype.clearUpdateMask = function() {
  return this.setUpdateMask(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sip.people.UpdateGroupRequest.prototype.hasUpdateMask = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sip.people.DeleteGroupRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.sip.people.DeleteGroupRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sip.people.DeleteGroupRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.people.DeleteGroupRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    auditMessage: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sip.people.DeleteGroupRequest}
 */
proto.sip.people.DeleteGroupRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sip.people.DeleteGroupRequest;
  return proto.sip.people.DeleteGroupRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sip.people.DeleteGroupRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sip.people.DeleteGroupRequest}
 */
proto.sip.people.DeleteGroupRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAuditMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sip.people.DeleteGroupRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sip.people.DeleteGroupRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sip.people.DeleteGroupRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.people.DeleteGroupRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAuditMessage();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.sip.people.DeleteGroupRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.sip.people.DeleteGroupRequest} returns this
 */
proto.sip.people.DeleteGroupRequest.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string audit_message = 2;
 * @return {string}
 */
proto.sip.people.DeleteGroupRequest.prototype.getAuditMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.sip.people.DeleteGroupRequest} returns this
 */
proto.sip.people.DeleteGroupRequest.prototype.setAuditMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.sip.people.ChangeGroupMembersRequest.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sip.people.ChangeGroupMembersRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.sip.people.ChangeGroupMembersRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sip.people.ChangeGroupMembersRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.people.ChangeGroupMembersRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    groupName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    userNamesList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f,
    auditMessage: jspb.Message.getFieldWithDefault(msg, 3, ""),
    action: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sip.people.ChangeGroupMembersRequest}
 */
proto.sip.people.ChangeGroupMembersRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sip.people.ChangeGroupMembersRequest;
  return proto.sip.people.ChangeGroupMembersRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sip.people.ChangeGroupMembersRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sip.people.ChangeGroupMembersRequest}
 */
proto.sip.people.ChangeGroupMembersRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setGroupName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addUserNames(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setAuditMessage(value);
      break;
    case 4:
      var value = /** @type {!proto.sip.people.ChangeGroupMembersRequest.ChangeGroupMembersAction} */ (reader.readEnum());
      msg.setAction(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sip.people.ChangeGroupMembersRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sip.people.ChangeGroupMembersRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sip.people.ChangeGroupMembersRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.people.ChangeGroupMembersRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGroupName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUserNamesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
  f = message.getAuditMessage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getAction();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.sip.people.ChangeGroupMembersRequest.ChangeGroupMembersAction = {
  CHANGE_GROUP_MEMBERS_ACTION_UNSPECIFIED: 0,
  CHANGE_GROUP_MEMBERS_ACTION_ADD: 1,
  CHANGE_GROUP_MEMBERS_ACTION_REMOVE: 2
};

/**
 * optional string group_name = 1;
 * @return {string}
 */
proto.sip.people.ChangeGroupMembersRequest.prototype.getGroupName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.sip.people.ChangeGroupMembersRequest} returns this
 */
proto.sip.people.ChangeGroupMembersRequest.prototype.setGroupName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated string user_names = 2;
 * @return {!Array<string>}
 */
proto.sip.people.ChangeGroupMembersRequest.prototype.getUserNamesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.sip.people.ChangeGroupMembersRequest} returns this
 */
proto.sip.people.ChangeGroupMembersRequest.prototype.setUserNamesList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.sip.people.ChangeGroupMembersRequest} returns this
 */
proto.sip.people.ChangeGroupMembersRequest.prototype.addUserNames = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sip.people.ChangeGroupMembersRequest} returns this
 */
proto.sip.people.ChangeGroupMembersRequest.prototype.clearUserNamesList = function() {
  return this.setUserNamesList([]);
};


/**
 * optional string audit_message = 3;
 * @return {string}
 */
proto.sip.people.ChangeGroupMembersRequest.prototype.getAuditMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.sip.people.ChangeGroupMembersRequest} returns this
 */
proto.sip.people.ChangeGroupMembersRequest.prototype.setAuditMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional ChangeGroupMembersAction action = 4;
 * @return {!proto.sip.people.ChangeGroupMembersRequest.ChangeGroupMembersAction}
 */
proto.sip.people.ChangeGroupMembersRequest.prototype.getAction = function() {
  return /** @type {!proto.sip.people.ChangeGroupMembersRequest.ChangeGroupMembersAction} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.sip.people.ChangeGroupMembersRequest.ChangeGroupMembersAction} value
 * @return {!proto.sip.people.ChangeGroupMembersRequest} returns this
 */
proto.sip.people.ChangeGroupMembersRequest.prototype.setAction = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sip.people.PostalAddress.prototype.toObject = function(opt_includeInstance) {
  return proto.sip.people.PostalAddress.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sip.people.PostalAddress} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.people.PostalAddress.toObject = function(includeInstance, msg) {
  var f, obj = {
    streetAddress: jspb.Message.getFieldWithDefault(msg, 1, ""),
    locality: jspb.Message.getFieldWithDefault(msg, 2, ""),
    postalCode: jspb.Message.getFieldWithDefault(msg, 3, ""),
    country: jspb.Message.getFieldWithDefault(msg, 4, ""),
    formatted: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sip.people.PostalAddress}
 */
proto.sip.people.PostalAddress.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sip.people.PostalAddress;
  return proto.sip.people.PostalAddress.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sip.people.PostalAddress} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sip.people.PostalAddress}
 */
proto.sip.people.PostalAddress.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setStreetAddress(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setLocality(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setPostalCode(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setCountry(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setFormatted(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sip.people.PostalAddress.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sip.people.PostalAddress.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sip.people.PostalAddress} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.people.PostalAddress.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStreetAddress();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLocality();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPostalCode();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getCountry();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getFormatted();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional string street_address = 1;
 * @return {string}
 */
proto.sip.people.PostalAddress.prototype.getStreetAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.sip.people.PostalAddress} returns this
 */
proto.sip.people.PostalAddress.prototype.setStreetAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string locality = 2;
 * @return {string}
 */
proto.sip.people.PostalAddress.prototype.getLocality = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.sip.people.PostalAddress} returns this
 */
proto.sip.people.PostalAddress.prototype.setLocality = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string postal_code = 3;
 * @return {string}
 */
proto.sip.people.PostalAddress.prototype.getPostalCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.sip.people.PostalAddress} returns this
 */
proto.sip.people.PostalAddress.prototype.setPostalCode = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string country = 4;
 * @return {string}
 */
proto.sip.people.PostalAddress.prototype.getCountry = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.sip.people.PostalAddress} returns this
 */
proto.sip.people.PostalAddress.prototype.setCountry = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string formatted = 5;
 * @return {string}
 */
proto.sip.people.PostalAddress.prototype.getFormatted = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.sip.people.PostalAddress} returns this
 */
proto.sip.people.PostalAddress.prototype.setFormatted = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sip.people.EmploymentInformation.prototype.toObject = function(opt_includeInstance) {
  return proto.sip.people.EmploymentInformation.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sip.people.EmploymentInformation} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.people.EmploymentInformation.toObject = function(includeInstance, msg) {
  var f, obj = {
    swissSalaryIdentifier: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sip.people.EmploymentInformation}
 */
proto.sip.people.EmploymentInformation.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sip.people.EmploymentInformation;
  return proto.sip.people.EmploymentInformation.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sip.people.EmploymentInformation} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sip.people.EmploymentInformation}
 */
proto.sip.people.EmploymentInformation.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSwissSalaryIdentifier(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sip.people.EmploymentInformation.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sip.people.EmploymentInformation.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sip.people.EmploymentInformation} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.people.EmploymentInformation.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSwissSalaryIdentifier();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string swiss_salary_identifier = 1;
 * @return {string}
 */
proto.sip.people.EmploymentInformation.prototype.getSwissSalaryIdentifier = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.sip.people.EmploymentInformation} returns this
 */
proto.sip.people.EmploymentInformation.prototype.setSwissSalaryIdentifier = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sip.people.BankAccount.prototype.toObject = function(opt_includeInstance) {
  return proto.sip.people.BankAccount.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sip.people.BankAccount} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.people.BankAccount.toObject = function(includeInstance, msg) {
  var f, obj = {
    bankingAddress: (f = msg.getBankingAddress()) && proto.sip.people.PostalAddress.toObject(includeInstance, f),
    bankName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    bic: jspb.Message.getFieldWithDefault(msg, 3, ""),
    iban: jspb.Message.getFieldWithDefault(msg, 4, ""),
    accountHolderName: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sip.people.BankAccount}
 */
proto.sip.people.BankAccount.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sip.people.BankAccount;
  return proto.sip.people.BankAccount.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sip.people.BankAccount} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sip.people.BankAccount}
 */
proto.sip.people.BankAccount.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.sip.people.PostalAddress;
      reader.readMessage(value,proto.sip.people.PostalAddress.deserializeBinaryFromReader);
      msg.setBankingAddress(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBankName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setBic(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setIban(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountHolderName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sip.people.BankAccount.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sip.people.BankAccount.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sip.people.BankAccount} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.people.BankAccount.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBankingAddress();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.sip.people.PostalAddress.serializeBinaryToWriter
    );
  }
  f = message.getBankName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getBic();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getIban();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getAccountHolderName();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional PostalAddress banking_address = 1;
 * @return {?proto.sip.people.PostalAddress}
 */
proto.sip.people.BankAccount.prototype.getBankingAddress = function() {
  return /** @type{?proto.sip.people.PostalAddress} */ (
    jspb.Message.getWrapperField(this, proto.sip.people.PostalAddress, 1));
};


/**
 * @param {?proto.sip.people.PostalAddress|undefined} value
 * @return {!proto.sip.people.BankAccount} returns this
*/
proto.sip.people.BankAccount.prototype.setBankingAddress = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sip.people.BankAccount} returns this
 */
proto.sip.people.BankAccount.prototype.clearBankingAddress = function() {
  return this.setBankingAddress(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sip.people.BankAccount.prototype.hasBankingAddress = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string bank_name = 2;
 * @return {string}
 */
proto.sip.people.BankAccount.prototype.getBankName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.sip.people.BankAccount} returns this
 */
proto.sip.people.BankAccount.prototype.setBankName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string bic = 3;
 * @return {string}
 */
proto.sip.people.BankAccount.prototype.getBic = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.sip.people.BankAccount} returns this
 */
proto.sip.people.BankAccount.prototype.setBic = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string iban = 4;
 * @return {string}
 */
proto.sip.people.BankAccount.prototype.getIban = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.sip.people.BankAccount} returns this
 */
proto.sip.people.BankAccount.prototype.setIban = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string account_holder_name = 5;
 * @return {string}
 */
proto.sip.people.BankAccount.prototype.getAccountHolderName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.sip.people.BankAccount} returns this
 */
proto.sip.people.BankAccount.prototype.setAccountHolderName = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sip.people.VsethMembershipInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.sip.people.VsethMembershipInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sip.people.VsethMembershipInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.people.VsethMembershipInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    membership: jspb.Message.getFieldWithDefault(msg, 1, 0),
    membershipType: jspb.Message.getFieldWithDefault(msg, 2, 0),
    fvOrgId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    fvOrgKey: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sip.people.VsethMembershipInfo}
 */
proto.sip.people.VsethMembershipInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sip.people.VsethMembershipInfo;
  return proto.sip.people.VsethMembershipInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sip.people.VsethMembershipInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sip.people.VsethMembershipInfo}
 */
proto.sip.people.VsethMembershipInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.sip.people.VsethMembership} */ (reader.readEnum());
      msg.setMembership(value);
      break;
    case 2:
      var value = /** @type {!proto.sip.people.VsethMembershipType} */ (reader.readEnum());
      msg.setMembershipType(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setFvOrgId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setFvOrgKey(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sip.people.VsethMembershipInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sip.people.VsethMembershipInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sip.people.VsethMembershipInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.people.VsethMembershipInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMembership();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getMembershipType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getFvOrgId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getFvOrgKey();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional VsethMembership membership = 1;
 * @return {!proto.sip.people.VsethMembership}
 */
proto.sip.people.VsethMembershipInfo.prototype.getMembership = function() {
  return /** @type {!proto.sip.people.VsethMembership} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.sip.people.VsethMembership} value
 * @return {!proto.sip.people.VsethMembershipInfo} returns this
 */
proto.sip.people.VsethMembershipInfo.prototype.setMembership = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional VsethMembershipType membership_type = 2;
 * @return {!proto.sip.people.VsethMembershipType}
 */
proto.sip.people.VsethMembershipInfo.prototype.getMembershipType = function() {
  return /** @type {!proto.sip.people.VsethMembershipType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.sip.people.VsethMembershipType} value
 * @return {!proto.sip.people.VsethMembershipInfo} returns this
 */
proto.sip.people.VsethMembershipInfo.prototype.setMembershipType = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional string fv_org_id = 3;
 * @return {string}
 */
proto.sip.people.VsethMembershipInfo.prototype.getFvOrgId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.sip.people.VsethMembershipInfo} returns this
 */
proto.sip.people.VsethMembershipInfo.prototype.setFvOrgId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string fv_org_key = 4;
 * @return {string}
 */
proto.sip.people.VsethMembershipInfo.prototype.getFvOrgKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.sip.people.VsethMembershipInfo} returns this
 */
proto.sip.people.VsethMembershipInfo.prototype.setFvOrgKey = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sip.people.EthStudyInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.sip.people.EthStudyInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sip.people.EthStudyInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.people.EthStudyInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    ethFachid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    department: jspb.Message.getFieldWithDefault(msg, 2, ""),
    departmentDisplayLabelEn: jspb.Message.getFieldWithDefault(msg, 3, ""),
    departmentDisplayLabelDe: jspb.Message.getFieldWithDefault(msg, 4, ""),
    studyProgramDisplayLabelEn: jspb.Message.getFieldWithDefault(msg, 5, ""),
    studyProgramDisplayLabelDe: jspb.Message.getFieldWithDefault(msg, 6, ""),
    studyFachrichtungDisplayLabelEn: jspb.Message.getFieldWithDefault(msg, 7, ""),
    studyFachrichtungDisplayLabelDe: jspb.Message.getFieldWithDefault(msg, 8, ""),
    exitDate: (f = msg.getExitDate()) && google_type_date_pb.Date.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sip.people.EthStudyInfo}
 */
proto.sip.people.EthStudyInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sip.people.EthStudyInfo;
  return proto.sip.people.EthStudyInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sip.people.EthStudyInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sip.people.EthStudyInfo}
 */
proto.sip.people.EthStudyInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEthFachid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDepartment(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDepartmentDisplayLabelEn(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setDepartmentDisplayLabelDe(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setStudyProgramDisplayLabelEn(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setStudyProgramDisplayLabelDe(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setStudyFachrichtungDisplayLabelEn(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setStudyFachrichtungDisplayLabelDe(value);
      break;
    case 9:
      var value = new google_type_date_pb.Date;
      reader.readMessage(value,google_type_date_pb.Date.deserializeBinaryFromReader);
      msg.setExitDate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sip.people.EthStudyInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sip.people.EthStudyInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sip.people.EthStudyInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.people.EthStudyInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEthFachid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDepartment();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDepartmentDisplayLabelEn();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDepartmentDisplayLabelDe();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getStudyProgramDisplayLabelEn();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getStudyProgramDisplayLabelDe();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getStudyFachrichtungDisplayLabelEn();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getStudyFachrichtungDisplayLabelDe();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getExitDate();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      google_type_date_pb.Date.serializeBinaryToWriter
    );
  }
};


/**
 * optional string eth_fachid = 1;
 * @return {string}
 */
proto.sip.people.EthStudyInfo.prototype.getEthFachid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.sip.people.EthStudyInfo} returns this
 */
proto.sip.people.EthStudyInfo.prototype.setEthFachid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string department = 2;
 * @return {string}
 */
proto.sip.people.EthStudyInfo.prototype.getDepartment = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.sip.people.EthStudyInfo} returns this
 */
proto.sip.people.EthStudyInfo.prototype.setDepartment = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string department_display_label_en = 3;
 * @return {string}
 */
proto.sip.people.EthStudyInfo.prototype.getDepartmentDisplayLabelEn = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.sip.people.EthStudyInfo} returns this
 */
proto.sip.people.EthStudyInfo.prototype.setDepartmentDisplayLabelEn = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string department_display_label_de = 4;
 * @return {string}
 */
proto.sip.people.EthStudyInfo.prototype.getDepartmentDisplayLabelDe = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.sip.people.EthStudyInfo} returns this
 */
proto.sip.people.EthStudyInfo.prototype.setDepartmentDisplayLabelDe = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string study_program_display_label_en = 5;
 * @return {string}
 */
proto.sip.people.EthStudyInfo.prototype.getStudyProgramDisplayLabelEn = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.sip.people.EthStudyInfo} returns this
 */
proto.sip.people.EthStudyInfo.prototype.setStudyProgramDisplayLabelEn = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string study_program_display_label_de = 6;
 * @return {string}
 */
proto.sip.people.EthStudyInfo.prototype.getStudyProgramDisplayLabelDe = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.sip.people.EthStudyInfo} returns this
 */
proto.sip.people.EthStudyInfo.prototype.setStudyProgramDisplayLabelDe = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string study_fachrichtung_display_label_en = 7;
 * @return {string}
 */
proto.sip.people.EthStudyInfo.prototype.getStudyFachrichtungDisplayLabelEn = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.sip.people.EthStudyInfo} returns this
 */
proto.sip.people.EthStudyInfo.prototype.setStudyFachrichtungDisplayLabelEn = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string study_fachrichtung_display_label_de = 8;
 * @return {string}
 */
proto.sip.people.EthStudyInfo.prototype.getStudyFachrichtungDisplayLabelDe = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.sip.people.EthStudyInfo} returns this
 */
proto.sip.people.EthStudyInfo.prototype.setStudyFachrichtungDisplayLabelDe = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional google.type.Date exit_date = 9;
 * @return {?proto.google.type.Date}
 */
proto.sip.people.EthStudyInfo.prototype.getExitDate = function() {
  return /** @type{?proto.google.type.Date} */ (
    jspb.Message.getWrapperField(this, google_type_date_pb.Date, 9));
};


/**
 * @param {?proto.google.type.Date|undefined} value
 * @return {!proto.sip.people.EthStudyInfo} returns this
*/
proto.sip.people.EthStudyInfo.prototype.setExitDate = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sip.people.EthStudyInfo} returns this
 */
proto.sip.people.EthStudyInfo.prototype.clearExitDate = function() {
  return this.setExitDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sip.people.EthStudyInfo.prototype.hasExitDate = function() {
  return jspb.Message.getField(this, 9) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.sip.people.User.repeatedFields_ = [19,22,26];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sip.people.User.prototype.toObject = function(opt_includeInstance) {
  return proto.sip.people.User.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sip.people.User} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.people.User.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    username: jspb.Message.getFieldWithDefault(msg, 2, ""),
    email: jspb.Message.getFieldWithDefault(msg, 3, ""),
    alternativeEmail: jspb.Message.getFieldWithDefault(msg, 4, ""),
    givenName: jspb.Message.getFieldWithDefault(msg, 5, ""),
    familyName: jspb.Message.getFieldWithDefault(msg, 6, ""),
    swissEduPersonUniqueId: jspb.Message.getFieldWithDefault(msg, 7, ""),
    studentNumber: jspb.Message.getFieldWithDefault(msg, 8, ""),
    studySemester: jspb.Message.getFieldWithDefault(msg, 9, 0),
    ethStudyInfo: (f = msg.getEthStudyInfo()) && proto.sip.people.EthStudyInfo.toObject(includeInstance, f),
    telephone: jspb.Message.getFieldWithDefault(msg, 11, ""),
    birthdate: (f = msg.getBirthdate()) && google_type_date_pb.Date.toObject(includeInstance, f),
    vsethMembershipInfo: (f = msg.getVsethMembershipInfo()) && proto.sip.people.VsethMembershipInfo.toObject(includeInstance, f),
    postalAddress: (f = msg.getPostalAddress()) && proto.sip.people.PostalAddress.toObject(includeInstance, f),
    bankAccount: (f = msg.getBankAccount()) && proto.sip.people.BankAccount.toObject(includeInstance, f),
    groupMembershipList: (f = jspb.Message.getRepeatedField(msg, 19)) == null ? undefined : f,
    groupMembershipTransitiveList: (f = jspb.Message.getRepeatedField(msg, 22)) == null ? undefined : f,
    groupMembershipTransitiveOrderList: (f = jspb.Message.getRepeatedField(msg, 26)) == null ? undefined : f,
    advantUid: jspb.Message.getFieldWithDefault(msg, 24, ""),
    kabaGroupHeader: jspb.Message.getFieldWithDefault(msg, 25, ""),
    createTime: (f = msg.getCreateTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    updateTime: (f = msg.getUpdateTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    view: jspb.Message.getFieldWithDefault(msg, 23, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sip.people.User}
 */
proto.sip.people.User.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sip.people.User;
  return proto.sip.people.User.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sip.people.User} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sip.people.User}
 */
proto.sip.people.User.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUsername(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setAlternativeEmail(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setGivenName(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setFamilyName(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setSwissEduPersonUniqueId(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setStudentNumber(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setStudySemester(value);
      break;
    case 10:
      var value = new proto.sip.people.EthStudyInfo;
      reader.readMessage(value,proto.sip.people.EthStudyInfo.deserializeBinaryFromReader);
      msg.setEthStudyInfo(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setTelephone(value);
      break;
    case 12:
      var value = new google_type_date_pb.Date;
      reader.readMessage(value,google_type_date_pb.Date.deserializeBinaryFromReader);
      msg.setBirthdate(value);
      break;
    case 13:
      var value = new proto.sip.people.VsethMembershipInfo;
      reader.readMessage(value,proto.sip.people.VsethMembershipInfo.deserializeBinaryFromReader);
      msg.setVsethMembershipInfo(value);
      break;
    case 16:
      var value = new proto.sip.people.PostalAddress;
      reader.readMessage(value,proto.sip.people.PostalAddress.deserializeBinaryFromReader);
      msg.setPostalAddress(value);
      break;
    case 17:
      var value = new proto.sip.people.BankAccount;
      reader.readMessage(value,proto.sip.people.BankAccount.deserializeBinaryFromReader);
      msg.setBankAccount(value);
      break;
    case 19:
      var value = /** @type {string} */ (reader.readString());
      msg.addGroupMembership(value);
      break;
    case 22:
      var value = /** @type {string} */ (reader.readString());
      msg.addGroupMembershipTransitive(value);
      break;
    case 26:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt32() : [reader.readInt32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addGroupMembershipTransitiveOrder(values[i]);
      }
      break;
    case 24:
      var value = /** @type {string} */ (reader.readString());
      msg.setAdvantUid(value);
      break;
    case 25:
      var value = /** @type {string} */ (reader.readString());
      msg.setKabaGroupHeader(value);
      break;
    case 20:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreateTime(value);
      break;
    case 21:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdateTime(value);
      break;
    case 23:
      var value = /** @type {!proto.sip.people.User.View} */ (reader.readEnum());
      msg.setView(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sip.people.User.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sip.people.User.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sip.people.User} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.people.User.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUsername();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getAlternativeEmail();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getGivenName();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getFamilyName();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getSwissEduPersonUniqueId();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getStudentNumber();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getStudySemester();
  if (f !== 0) {
    writer.writeInt32(
      9,
      f
    );
  }
  f = message.getEthStudyInfo();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      proto.sip.people.EthStudyInfo.serializeBinaryToWriter
    );
  }
  f = message.getTelephone();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getBirthdate();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      google_type_date_pb.Date.serializeBinaryToWriter
    );
  }
  f = message.getVsethMembershipInfo();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      proto.sip.people.VsethMembershipInfo.serializeBinaryToWriter
    );
  }
  f = message.getPostalAddress();
  if (f != null) {
    writer.writeMessage(
      16,
      f,
      proto.sip.people.PostalAddress.serializeBinaryToWriter
    );
  }
  f = message.getBankAccount();
  if (f != null) {
    writer.writeMessage(
      17,
      f,
      proto.sip.people.BankAccount.serializeBinaryToWriter
    );
  }
  f = message.getGroupMembershipList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      19,
      f
    );
  }
  f = message.getGroupMembershipTransitiveList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      22,
      f
    );
  }
  f = message.getGroupMembershipTransitiveOrderList();
  if (f.length > 0) {
    writer.writePackedInt32(
      26,
      f
    );
  }
  f = message.getAdvantUid();
  if (f.length > 0) {
    writer.writeString(
      24,
      f
    );
  }
  f = message.getKabaGroupHeader();
  if (f.length > 0) {
    writer.writeString(
      25,
      f
    );
  }
  f = message.getCreateTime();
  if (f != null) {
    writer.writeMessage(
      20,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getUpdateTime();
  if (f != null) {
    writer.writeMessage(
      21,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getView();
  if (f !== 0.0) {
    writer.writeEnum(
      23,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.sip.people.User.View = {
  VIEW_UNSPECIFIED: 0,
  BASIC: 1,
  INCLUDE_MEMBERSHIPS: 2,
  KEYCLOAK: 3
};


/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.sip.people.User.Filter.repeatedFields_ = [22];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sip.people.User.Filter.prototype.toObject = function(opt_includeInstance) {
  return proto.sip.people.User.Filter.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sip.people.User.Filter} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.people.User.Filter.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    username: jspb.Message.getFieldWithDefault(msg, 2, ""),
    email: jspb.Message.getFieldWithDefault(msg, 3, ""),
    alternativeEmail: jspb.Message.getFieldWithDefault(msg, 4, ""),
    givenName: jspb.Message.getFieldWithDefault(msg, 5, ""),
    familyName: jspb.Message.getFieldWithDefault(msg, 6, ""),
    swissEduPersonUniqueId: jspb.Message.getFieldWithDefault(msg, 7, ""),
    studentNumber: jspb.Message.getFieldWithDefault(msg, 8, ""),
    studySemester: jspb.Message.getFieldWithDefault(msg, 9, 0),
    telephone: jspb.Message.getFieldWithDefault(msg, 11, ""),
    birthdate: (f = msg.getBirthdate()) && google_type_date_pb.Date.toObject(includeInstance, f),
    vsethMembershipInfo: (f = msg.getVsethMembershipInfo()) && proto.sip.people.VsethMembershipInfo.toObject(includeInstance, f),
    groupMembershipTransitiveList: (f = jspb.Message.getRepeatedField(msg, 22)) == null ? undefined : f,
    advantUid: jspb.Message.getFieldWithDefault(msg, 24, ""),
    kabaGroupHeader: jspb.Message.getFieldWithDefault(msg, 25, ""),
    descending: jspb.Message.getBooleanFieldWithDefault(msg, 30, false),
    sortBy: jspb.Message.getFieldWithDefault(msg, 31, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sip.people.User.Filter}
 */
proto.sip.people.User.Filter.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sip.people.User.Filter;
  return proto.sip.people.User.Filter.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sip.people.User.Filter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sip.people.User.Filter}
 */
proto.sip.people.User.Filter.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUsername(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setAlternativeEmail(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setGivenName(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setFamilyName(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setSwissEduPersonUniqueId(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setStudentNumber(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setStudySemester(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setTelephone(value);
      break;
    case 12:
      var value = new google_type_date_pb.Date;
      reader.readMessage(value,google_type_date_pb.Date.deserializeBinaryFromReader);
      msg.setBirthdate(value);
      break;
    case 13:
      var value = new proto.sip.people.VsethMembershipInfo;
      reader.readMessage(value,proto.sip.people.VsethMembershipInfo.deserializeBinaryFromReader);
      msg.setVsethMembershipInfo(value);
      break;
    case 22:
      var value = /** @type {string} */ (reader.readString());
      msg.addGroupMembershipTransitive(value);
      break;
    case 24:
      var value = /** @type {string} */ (reader.readString());
      msg.setAdvantUid(value);
      break;
    case 25:
      var value = /** @type {string} */ (reader.readString());
      msg.setKabaGroupHeader(value);
      break;
    case 30:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDescending(value);
      break;
    case 31:
      var value = /** @type {!proto.sip.people.User.Filter.SortBy} */ (reader.readEnum());
      msg.setSortBy(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sip.people.User.Filter.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sip.people.User.Filter.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sip.people.User.Filter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.people.User.Filter.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUsername();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getAlternativeEmail();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getGivenName();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getFamilyName();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getSwissEduPersonUniqueId();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getStudentNumber();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getStudySemester();
  if (f !== 0) {
    writer.writeInt32(
      9,
      f
    );
  }
  f = message.getTelephone();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getBirthdate();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      google_type_date_pb.Date.serializeBinaryToWriter
    );
  }
  f = message.getVsethMembershipInfo();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      proto.sip.people.VsethMembershipInfo.serializeBinaryToWriter
    );
  }
  f = message.getGroupMembershipTransitiveList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      22,
      f
    );
  }
  f = message.getAdvantUid();
  if (f.length > 0) {
    writer.writeString(
      24,
      f
    );
  }
  f = message.getKabaGroupHeader();
  if (f.length > 0) {
    writer.writeString(
      25,
      f
    );
  }
  f = message.getDescending();
  if (f) {
    writer.writeBool(
      30,
      f
    );
  }
  f = message.getSortBy();
  if (f !== 0.0) {
    writer.writeEnum(
      31,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.sip.people.User.Filter.SortBy = {
  NAME: 0,
  USERNAME: 1,
  EMAIL: 2
};

/**
 * optional string name = 1;
 * @return {string}
 */
proto.sip.people.User.Filter.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.sip.people.User.Filter} returns this
 */
proto.sip.people.User.Filter.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string username = 2;
 * @return {string}
 */
proto.sip.people.User.Filter.prototype.getUsername = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.sip.people.User.Filter} returns this
 */
proto.sip.people.User.Filter.prototype.setUsername = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string email = 3;
 * @return {string}
 */
proto.sip.people.User.Filter.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.sip.people.User.Filter} returns this
 */
proto.sip.people.User.Filter.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string alternative_email = 4;
 * @return {string}
 */
proto.sip.people.User.Filter.prototype.getAlternativeEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.sip.people.User.Filter} returns this
 */
proto.sip.people.User.Filter.prototype.setAlternativeEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string given_name = 5;
 * @return {string}
 */
proto.sip.people.User.Filter.prototype.getGivenName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.sip.people.User.Filter} returns this
 */
proto.sip.people.User.Filter.prototype.setGivenName = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string family_name = 6;
 * @return {string}
 */
proto.sip.people.User.Filter.prototype.getFamilyName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.sip.people.User.Filter} returns this
 */
proto.sip.people.User.Filter.prototype.setFamilyName = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string swiss_edu_person_unique_id = 7;
 * @return {string}
 */
proto.sip.people.User.Filter.prototype.getSwissEduPersonUniqueId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.sip.people.User.Filter} returns this
 */
proto.sip.people.User.Filter.prototype.setSwissEduPersonUniqueId = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string student_number = 8;
 * @return {string}
 */
proto.sip.people.User.Filter.prototype.getStudentNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.sip.people.User.Filter} returns this
 */
proto.sip.people.User.Filter.prototype.setStudentNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional int32 study_semester = 9;
 * @return {number}
 */
proto.sip.people.User.Filter.prototype.getStudySemester = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.sip.people.User.Filter} returns this
 */
proto.sip.people.User.Filter.prototype.setStudySemester = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional string telephone = 11;
 * @return {string}
 */
proto.sip.people.User.Filter.prototype.getTelephone = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.sip.people.User.Filter} returns this
 */
proto.sip.people.User.Filter.prototype.setTelephone = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional google.type.Date birthdate = 12;
 * @return {?proto.google.type.Date}
 */
proto.sip.people.User.Filter.prototype.getBirthdate = function() {
  return /** @type{?proto.google.type.Date} */ (
    jspb.Message.getWrapperField(this, google_type_date_pb.Date, 12));
};


/**
 * @param {?proto.google.type.Date|undefined} value
 * @return {!proto.sip.people.User.Filter} returns this
*/
proto.sip.people.User.Filter.prototype.setBirthdate = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sip.people.User.Filter} returns this
 */
proto.sip.people.User.Filter.prototype.clearBirthdate = function() {
  return this.setBirthdate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sip.people.User.Filter.prototype.hasBirthdate = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional VsethMembershipInfo vseth_membership_info = 13;
 * @return {?proto.sip.people.VsethMembershipInfo}
 */
proto.sip.people.User.Filter.prototype.getVsethMembershipInfo = function() {
  return /** @type{?proto.sip.people.VsethMembershipInfo} */ (
    jspb.Message.getWrapperField(this, proto.sip.people.VsethMembershipInfo, 13));
};


/**
 * @param {?proto.sip.people.VsethMembershipInfo|undefined} value
 * @return {!proto.sip.people.User.Filter} returns this
*/
proto.sip.people.User.Filter.prototype.setVsethMembershipInfo = function(value) {
  return jspb.Message.setWrapperField(this, 13, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sip.people.User.Filter} returns this
 */
proto.sip.people.User.Filter.prototype.clearVsethMembershipInfo = function() {
  return this.setVsethMembershipInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sip.people.User.Filter.prototype.hasVsethMembershipInfo = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * repeated string group_membership_transitive = 22;
 * @return {!Array<string>}
 */
proto.sip.people.User.Filter.prototype.getGroupMembershipTransitiveList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 22));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.sip.people.User.Filter} returns this
 */
proto.sip.people.User.Filter.prototype.setGroupMembershipTransitiveList = function(value) {
  return jspb.Message.setField(this, 22, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.sip.people.User.Filter} returns this
 */
proto.sip.people.User.Filter.prototype.addGroupMembershipTransitive = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 22, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sip.people.User.Filter} returns this
 */
proto.sip.people.User.Filter.prototype.clearGroupMembershipTransitiveList = function() {
  return this.setGroupMembershipTransitiveList([]);
};


/**
 * optional string advant_uid = 24;
 * @return {string}
 */
proto.sip.people.User.Filter.prototype.getAdvantUid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 24, ""));
};


/**
 * @param {string} value
 * @return {!proto.sip.people.User.Filter} returns this
 */
proto.sip.people.User.Filter.prototype.setAdvantUid = function(value) {
  return jspb.Message.setProto3StringField(this, 24, value);
};


/**
 * optional string kaba_group_header = 25;
 * @return {string}
 */
proto.sip.people.User.Filter.prototype.getKabaGroupHeader = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 25, ""));
};


/**
 * @param {string} value
 * @return {!proto.sip.people.User.Filter} returns this
 */
proto.sip.people.User.Filter.prototype.setKabaGroupHeader = function(value) {
  return jspb.Message.setProto3StringField(this, 25, value);
};


/**
 * optional bool descending = 30;
 * @return {boolean}
 */
proto.sip.people.User.Filter.prototype.getDescending = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 30, false));
};


/**
 * @param {boolean} value
 * @return {!proto.sip.people.User.Filter} returns this
 */
proto.sip.people.User.Filter.prototype.setDescending = function(value) {
  return jspb.Message.setProto3BooleanField(this, 30, value);
};


/**
 * optional SortBy sort_by = 31;
 * @return {!proto.sip.people.User.Filter.SortBy}
 */
proto.sip.people.User.Filter.prototype.getSortBy = function() {
  return /** @type {!proto.sip.people.User.Filter.SortBy} */ (jspb.Message.getFieldWithDefault(this, 31, 0));
};


/**
 * @param {!proto.sip.people.User.Filter.SortBy} value
 * @return {!proto.sip.people.User.Filter} returns this
 */
proto.sip.people.User.Filter.prototype.setSortBy = function(value) {
  return jspb.Message.setProto3EnumField(this, 31, value);
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.sip.people.User.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.sip.people.User} returns this
 */
proto.sip.people.User.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string username = 2;
 * @return {string}
 */
proto.sip.people.User.prototype.getUsername = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.sip.people.User} returns this
 */
proto.sip.people.User.prototype.setUsername = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string email = 3;
 * @return {string}
 */
proto.sip.people.User.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.sip.people.User} returns this
 */
proto.sip.people.User.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string alternative_email = 4;
 * @return {string}
 */
proto.sip.people.User.prototype.getAlternativeEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.sip.people.User} returns this
 */
proto.sip.people.User.prototype.setAlternativeEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string given_name = 5;
 * @return {string}
 */
proto.sip.people.User.prototype.getGivenName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.sip.people.User} returns this
 */
proto.sip.people.User.prototype.setGivenName = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string family_name = 6;
 * @return {string}
 */
proto.sip.people.User.prototype.getFamilyName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.sip.people.User} returns this
 */
proto.sip.people.User.prototype.setFamilyName = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string swiss_edu_person_unique_id = 7;
 * @return {string}
 */
proto.sip.people.User.prototype.getSwissEduPersonUniqueId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.sip.people.User} returns this
 */
proto.sip.people.User.prototype.setSwissEduPersonUniqueId = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string student_number = 8;
 * @return {string}
 */
proto.sip.people.User.prototype.getStudentNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.sip.people.User} returns this
 */
proto.sip.people.User.prototype.setStudentNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional int32 study_semester = 9;
 * @return {number}
 */
proto.sip.people.User.prototype.getStudySemester = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.sip.people.User} returns this
 */
proto.sip.people.User.prototype.setStudySemester = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional EthStudyInfo eth_study_info = 10;
 * @return {?proto.sip.people.EthStudyInfo}
 */
proto.sip.people.User.prototype.getEthStudyInfo = function() {
  return /** @type{?proto.sip.people.EthStudyInfo} */ (
    jspb.Message.getWrapperField(this, proto.sip.people.EthStudyInfo, 10));
};


/**
 * @param {?proto.sip.people.EthStudyInfo|undefined} value
 * @return {!proto.sip.people.User} returns this
*/
proto.sip.people.User.prototype.setEthStudyInfo = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sip.people.User} returns this
 */
proto.sip.people.User.prototype.clearEthStudyInfo = function() {
  return this.setEthStudyInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sip.people.User.prototype.hasEthStudyInfo = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional string telephone = 11;
 * @return {string}
 */
proto.sip.people.User.prototype.getTelephone = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.sip.people.User} returns this
 */
proto.sip.people.User.prototype.setTelephone = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional google.type.Date birthdate = 12;
 * @return {?proto.google.type.Date}
 */
proto.sip.people.User.prototype.getBirthdate = function() {
  return /** @type{?proto.google.type.Date} */ (
    jspb.Message.getWrapperField(this, google_type_date_pb.Date, 12));
};


/**
 * @param {?proto.google.type.Date|undefined} value
 * @return {!proto.sip.people.User} returns this
*/
proto.sip.people.User.prototype.setBirthdate = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sip.people.User} returns this
 */
proto.sip.people.User.prototype.clearBirthdate = function() {
  return this.setBirthdate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sip.people.User.prototype.hasBirthdate = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional VsethMembershipInfo vseth_membership_info = 13;
 * @return {?proto.sip.people.VsethMembershipInfo}
 */
proto.sip.people.User.prototype.getVsethMembershipInfo = function() {
  return /** @type{?proto.sip.people.VsethMembershipInfo} */ (
    jspb.Message.getWrapperField(this, proto.sip.people.VsethMembershipInfo, 13));
};


/**
 * @param {?proto.sip.people.VsethMembershipInfo|undefined} value
 * @return {!proto.sip.people.User} returns this
*/
proto.sip.people.User.prototype.setVsethMembershipInfo = function(value) {
  return jspb.Message.setWrapperField(this, 13, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sip.people.User} returns this
 */
proto.sip.people.User.prototype.clearVsethMembershipInfo = function() {
  return this.setVsethMembershipInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sip.people.User.prototype.hasVsethMembershipInfo = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional PostalAddress postal_address = 16;
 * @return {?proto.sip.people.PostalAddress}
 */
proto.sip.people.User.prototype.getPostalAddress = function() {
  return /** @type{?proto.sip.people.PostalAddress} */ (
    jspb.Message.getWrapperField(this, proto.sip.people.PostalAddress, 16));
};


/**
 * @param {?proto.sip.people.PostalAddress|undefined} value
 * @return {!proto.sip.people.User} returns this
*/
proto.sip.people.User.prototype.setPostalAddress = function(value) {
  return jspb.Message.setWrapperField(this, 16, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sip.people.User} returns this
 */
proto.sip.people.User.prototype.clearPostalAddress = function() {
  return this.setPostalAddress(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sip.people.User.prototype.hasPostalAddress = function() {
  return jspb.Message.getField(this, 16) != null;
};


/**
 * optional BankAccount bank_account = 17;
 * @return {?proto.sip.people.BankAccount}
 */
proto.sip.people.User.prototype.getBankAccount = function() {
  return /** @type{?proto.sip.people.BankAccount} */ (
    jspb.Message.getWrapperField(this, proto.sip.people.BankAccount, 17));
};


/**
 * @param {?proto.sip.people.BankAccount|undefined} value
 * @return {!proto.sip.people.User} returns this
*/
proto.sip.people.User.prototype.setBankAccount = function(value) {
  return jspb.Message.setWrapperField(this, 17, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sip.people.User} returns this
 */
proto.sip.people.User.prototype.clearBankAccount = function() {
  return this.setBankAccount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sip.people.User.prototype.hasBankAccount = function() {
  return jspb.Message.getField(this, 17) != null;
};


/**
 * repeated string group_membership = 19;
 * @return {!Array<string>}
 */
proto.sip.people.User.prototype.getGroupMembershipList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 19));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.sip.people.User} returns this
 */
proto.sip.people.User.prototype.setGroupMembershipList = function(value) {
  return jspb.Message.setField(this, 19, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.sip.people.User} returns this
 */
proto.sip.people.User.prototype.addGroupMembership = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 19, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sip.people.User} returns this
 */
proto.sip.people.User.prototype.clearGroupMembershipList = function() {
  return this.setGroupMembershipList([]);
};


/**
 * repeated string group_membership_transitive = 22;
 * @return {!Array<string>}
 */
proto.sip.people.User.prototype.getGroupMembershipTransitiveList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 22));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.sip.people.User} returns this
 */
proto.sip.people.User.prototype.setGroupMembershipTransitiveList = function(value) {
  return jspb.Message.setField(this, 22, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.sip.people.User} returns this
 */
proto.sip.people.User.prototype.addGroupMembershipTransitive = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 22, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sip.people.User} returns this
 */
proto.sip.people.User.prototype.clearGroupMembershipTransitiveList = function() {
  return this.setGroupMembershipTransitiveList([]);
};


/**
 * repeated int32 group_membership_transitive_order = 26;
 * @return {!Array<number>}
 */
proto.sip.people.User.prototype.getGroupMembershipTransitiveOrderList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 26));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.sip.people.User} returns this
 */
proto.sip.people.User.prototype.setGroupMembershipTransitiveOrderList = function(value) {
  return jspb.Message.setField(this, 26, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.sip.people.User} returns this
 */
proto.sip.people.User.prototype.addGroupMembershipTransitiveOrder = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 26, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sip.people.User} returns this
 */
proto.sip.people.User.prototype.clearGroupMembershipTransitiveOrderList = function() {
  return this.setGroupMembershipTransitiveOrderList([]);
};


/**
 * optional string advant_uid = 24;
 * @return {string}
 */
proto.sip.people.User.prototype.getAdvantUid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 24, ""));
};


/**
 * @param {string} value
 * @return {!proto.sip.people.User} returns this
 */
proto.sip.people.User.prototype.setAdvantUid = function(value) {
  return jspb.Message.setProto3StringField(this, 24, value);
};


/**
 * optional string kaba_group_header = 25;
 * @return {string}
 */
proto.sip.people.User.prototype.getKabaGroupHeader = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 25, ""));
};


/**
 * @param {string} value
 * @return {!proto.sip.people.User} returns this
 */
proto.sip.people.User.prototype.setKabaGroupHeader = function(value) {
  return jspb.Message.setProto3StringField(this, 25, value);
};


/**
 * optional google.protobuf.Timestamp create_time = 20;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.sip.people.User.prototype.getCreateTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 20));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.sip.people.User} returns this
*/
proto.sip.people.User.prototype.setCreateTime = function(value) {
  return jspb.Message.setWrapperField(this, 20, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sip.people.User} returns this
 */
proto.sip.people.User.prototype.clearCreateTime = function() {
  return this.setCreateTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sip.people.User.prototype.hasCreateTime = function() {
  return jspb.Message.getField(this, 20) != null;
};


/**
 * optional google.protobuf.Timestamp update_time = 21;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.sip.people.User.prototype.getUpdateTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 21));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.sip.people.User} returns this
*/
proto.sip.people.User.prototype.setUpdateTime = function(value) {
  return jspb.Message.setWrapperField(this, 21, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sip.people.User} returns this
 */
proto.sip.people.User.prototype.clearUpdateTime = function() {
  return this.setUpdateTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sip.people.User.prototype.hasUpdateTime = function() {
  return jspb.Message.getField(this, 21) != null;
};


/**
 * optional View view = 23;
 * @return {!proto.sip.people.User.View}
 */
proto.sip.people.User.prototype.getView = function() {
  return /** @type {!proto.sip.people.User.View} */ (jspb.Message.getFieldWithDefault(this, 23, 0));
};


/**
 * @param {!proto.sip.people.User.View} value
 * @return {!proto.sip.people.User} returns this
 */
proto.sip.people.User.prototype.setView = function(value) {
  return jspb.Message.setProto3EnumField(this, 23, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sip.people.ListUsersRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.sip.people.ListUsersRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sip.people.ListUsersRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.people.ListUsersRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    pageSize: jspb.Message.getFieldWithDefault(msg, 1, 0),
    pageToken: jspb.Message.getFieldWithDefault(msg, 2, ""),
    filter: (f = msg.getFilter()) && proto.sip.people.User.Filter.toObject(includeInstance, f),
    view: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sip.people.ListUsersRequest}
 */
proto.sip.people.ListUsersRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sip.people.ListUsersRequest;
  return proto.sip.people.ListUsersRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sip.people.ListUsersRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sip.people.ListUsersRequest}
 */
proto.sip.people.ListUsersRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPageSize(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPageToken(value);
      break;
    case 3:
      var value = new proto.sip.people.User.Filter;
      reader.readMessage(value,proto.sip.people.User.Filter.deserializeBinaryFromReader);
      msg.setFilter(value);
      break;
    case 4:
      var value = /** @type {!proto.sip.people.User.View} */ (reader.readEnum());
      msg.setView(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sip.people.ListUsersRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sip.people.ListUsersRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sip.people.ListUsersRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.people.ListUsersRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPageSize();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getPageToken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getFilter();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.sip.people.User.Filter.serializeBinaryToWriter
    );
  }
  f = message.getView();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
};


/**
 * optional int32 page_size = 1;
 * @return {number}
 */
proto.sip.people.ListUsersRequest.prototype.getPageSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.sip.people.ListUsersRequest} returns this
 */
proto.sip.people.ListUsersRequest.prototype.setPageSize = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string page_token = 2;
 * @return {string}
 */
proto.sip.people.ListUsersRequest.prototype.getPageToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.sip.people.ListUsersRequest} returns this
 */
proto.sip.people.ListUsersRequest.prototype.setPageToken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional User.Filter filter = 3;
 * @return {?proto.sip.people.User.Filter}
 */
proto.sip.people.ListUsersRequest.prototype.getFilter = function() {
  return /** @type{?proto.sip.people.User.Filter} */ (
    jspb.Message.getWrapperField(this, proto.sip.people.User.Filter, 3));
};


/**
 * @param {?proto.sip.people.User.Filter|undefined} value
 * @return {!proto.sip.people.ListUsersRequest} returns this
*/
proto.sip.people.ListUsersRequest.prototype.setFilter = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sip.people.ListUsersRequest} returns this
 */
proto.sip.people.ListUsersRequest.prototype.clearFilter = function() {
  return this.setFilter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sip.people.ListUsersRequest.prototype.hasFilter = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional User.View view = 4;
 * @return {!proto.sip.people.User.View}
 */
proto.sip.people.ListUsersRequest.prototype.getView = function() {
  return /** @type {!proto.sip.people.User.View} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.sip.people.User.View} value
 * @return {!proto.sip.people.ListUsersRequest} returns this
 */
proto.sip.people.ListUsersRequest.prototype.setView = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.sip.people.ListUsersResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sip.people.ListUsersResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.sip.people.ListUsersResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sip.people.ListUsersResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.people.ListUsersResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    usersList: jspb.Message.toObjectList(msg.getUsersList(),
    proto.sip.people.User.toObject, includeInstance),
    nextPageToken: jspb.Message.getFieldWithDefault(msg, 2, ""),
    totalSize: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sip.people.ListUsersResponse}
 */
proto.sip.people.ListUsersResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sip.people.ListUsersResponse;
  return proto.sip.people.ListUsersResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sip.people.ListUsersResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sip.people.ListUsersResponse}
 */
proto.sip.people.ListUsersResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.sip.people.User;
      reader.readMessage(value,proto.sip.people.User.deserializeBinaryFromReader);
      msg.addUsers(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setNextPageToken(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotalSize(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sip.people.ListUsersResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sip.people.ListUsersResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sip.people.ListUsersResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.people.ListUsersResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUsersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.sip.people.User.serializeBinaryToWriter
    );
  }
  f = message.getNextPageToken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTotalSize();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
};


/**
 * repeated User users = 1;
 * @return {!Array<!proto.sip.people.User>}
 */
proto.sip.people.ListUsersResponse.prototype.getUsersList = function() {
  return /** @type{!Array<!proto.sip.people.User>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.sip.people.User, 1));
};


/**
 * @param {!Array<!proto.sip.people.User>} value
 * @return {!proto.sip.people.ListUsersResponse} returns this
*/
proto.sip.people.ListUsersResponse.prototype.setUsersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.sip.people.User=} opt_value
 * @param {number=} opt_index
 * @return {!proto.sip.people.User}
 */
proto.sip.people.ListUsersResponse.prototype.addUsers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.sip.people.User, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sip.people.ListUsersResponse} returns this
 */
proto.sip.people.ListUsersResponse.prototype.clearUsersList = function() {
  return this.setUsersList([]);
};


/**
 * optional string next_page_token = 2;
 * @return {string}
 */
proto.sip.people.ListUsersResponse.prototype.getNextPageToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.sip.people.ListUsersResponse} returns this
 */
proto.sip.people.ListUsersResponse.prototype.setNextPageToken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 total_size = 3;
 * @return {number}
 */
proto.sip.people.ListUsersResponse.prototype.getTotalSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.sip.people.ListUsersResponse} returns this
 */
proto.sip.people.ListUsersResponse.prototype.setTotalSize = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sip.people.GetUserRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.sip.people.GetUserRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sip.people.GetUserRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.people.GetUserRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    view: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sip.people.GetUserRequest}
 */
proto.sip.people.GetUserRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sip.people.GetUserRequest;
  return proto.sip.people.GetUserRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sip.people.GetUserRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sip.people.GetUserRequest}
 */
proto.sip.people.GetUserRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {!proto.sip.people.User.View} */ (reader.readEnum());
      msg.setView(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sip.people.GetUserRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sip.people.GetUserRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sip.people.GetUserRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.people.GetUserRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getView();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.sip.people.GetUserRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.sip.people.GetUserRequest} returns this
 */
proto.sip.people.GetUserRequest.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional User.View view = 2;
 * @return {!proto.sip.people.User.View}
 */
proto.sip.people.GetUserRequest.prototype.getView = function() {
  return /** @type {!proto.sip.people.User.View} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.sip.people.User.View} value
 * @return {!proto.sip.people.GetUserRequest} returns this
 */
proto.sip.people.GetUserRequest.prototype.setView = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.sip.people.BatchGetUserRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sip.people.BatchGetUserRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.sip.people.BatchGetUserRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sip.people.BatchGetUserRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.people.BatchGetUserRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    namesList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    view: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sip.people.BatchGetUserRequest}
 */
proto.sip.people.BatchGetUserRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sip.people.BatchGetUserRequest;
  return proto.sip.people.BatchGetUserRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sip.people.BatchGetUserRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sip.people.BatchGetUserRequest}
 */
proto.sip.people.BatchGetUserRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addNames(value);
      break;
    case 2:
      var value = /** @type {!proto.sip.people.User.View} */ (reader.readEnum());
      msg.setView(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sip.people.BatchGetUserRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sip.people.BatchGetUserRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sip.people.BatchGetUserRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.people.BatchGetUserRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNamesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
  f = message.getView();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * repeated string names = 1;
 * @return {!Array<string>}
 */
proto.sip.people.BatchGetUserRequest.prototype.getNamesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.sip.people.BatchGetUserRequest} returns this
 */
proto.sip.people.BatchGetUserRequest.prototype.setNamesList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.sip.people.BatchGetUserRequest} returns this
 */
proto.sip.people.BatchGetUserRequest.prototype.addNames = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sip.people.BatchGetUserRequest} returns this
 */
proto.sip.people.BatchGetUserRequest.prototype.clearNamesList = function() {
  return this.setNamesList([]);
};


/**
 * optional User.View view = 2;
 * @return {!proto.sip.people.User.View}
 */
proto.sip.people.BatchGetUserRequest.prototype.getView = function() {
  return /** @type {!proto.sip.people.User.View} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.sip.people.User.View} value
 * @return {!proto.sip.people.BatchGetUserRequest} returns this
 */
proto.sip.people.BatchGetUserRequest.prototype.setView = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.sip.people.BatchGetUserResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sip.people.BatchGetUserResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.sip.people.BatchGetUserResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sip.people.BatchGetUserResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.people.BatchGetUserResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    usersList: jspb.Message.toObjectList(msg.getUsersList(),
    proto.sip.people.User.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sip.people.BatchGetUserResponse}
 */
proto.sip.people.BatchGetUserResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sip.people.BatchGetUserResponse;
  return proto.sip.people.BatchGetUserResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sip.people.BatchGetUserResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sip.people.BatchGetUserResponse}
 */
proto.sip.people.BatchGetUserResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.sip.people.User;
      reader.readMessage(value,proto.sip.people.User.deserializeBinaryFromReader);
      msg.addUsers(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sip.people.BatchGetUserResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sip.people.BatchGetUserResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sip.people.BatchGetUserResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.people.BatchGetUserResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUsersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.sip.people.User.serializeBinaryToWriter
    );
  }
};


/**
 * repeated User users = 1;
 * @return {!Array<!proto.sip.people.User>}
 */
proto.sip.people.BatchGetUserResponse.prototype.getUsersList = function() {
  return /** @type{!Array<!proto.sip.people.User>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.sip.people.User, 1));
};


/**
 * @param {!Array<!proto.sip.people.User>} value
 * @return {!proto.sip.people.BatchGetUserResponse} returns this
*/
proto.sip.people.BatchGetUserResponse.prototype.setUsersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.sip.people.User=} opt_value
 * @param {number=} opt_index
 * @return {!proto.sip.people.User}
 */
proto.sip.people.BatchGetUserResponse.prototype.addUsers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.sip.people.User, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sip.people.BatchGetUserResponse} returns this
 */
proto.sip.people.BatchGetUserResponse.prototype.clearUsersList = function() {
  return this.setUsersList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sip.people.CreateUserRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.sip.people.CreateUserRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sip.people.CreateUserRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.people.CreateUserRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    user: (f = msg.getUser()) && proto.sip.people.User.toObject(includeInstance, f),
    auditMessage: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sip.people.CreateUserRequest}
 */
proto.sip.people.CreateUserRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sip.people.CreateUserRequest;
  return proto.sip.people.CreateUserRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sip.people.CreateUserRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sip.people.CreateUserRequest}
 */
proto.sip.people.CreateUserRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.sip.people.User;
      reader.readMessage(value,proto.sip.people.User.deserializeBinaryFromReader);
      msg.setUser(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAuditMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sip.people.CreateUserRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sip.people.CreateUserRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sip.people.CreateUserRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.people.CreateUserRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUser();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.sip.people.User.serializeBinaryToWriter
    );
  }
  f = message.getAuditMessage();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional User user = 1;
 * @return {?proto.sip.people.User}
 */
proto.sip.people.CreateUserRequest.prototype.getUser = function() {
  return /** @type{?proto.sip.people.User} */ (
    jspb.Message.getWrapperField(this, proto.sip.people.User, 1));
};


/**
 * @param {?proto.sip.people.User|undefined} value
 * @return {!proto.sip.people.CreateUserRequest} returns this
*/
proto.sip.people.CreateUserRequest.prototype.setUser = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sip.people.CreateUserRequest} returns this
 */
proto.sip.people.CreateUserRequest.prototype.clearUser = function() {
  return this.setUser(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sip.people.CreateUserRequest.prototype.hasUser = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string audit_message = 2;
 * @return {string}
 */
proto.sip.people.CreateUserRequest.prototype.getAuditMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.sip.people.CreateUserRequest} returns this
 */
proto.sip.people.CreateUserRequest.prototype.setAuditMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sip.people.UpdateUserRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.sip.people.UpdateUserRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sip.people.UpdateUserRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.people.UpdateUserRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    user: (f = msg.getUser()) && proto.sip.people.User.toObject(includeInstance, f),
    updateMask: (f = msg.getUpdateMask()) && google_protobuf_field_mask_pb.FieldMask.toObject(includeInstance, f),
    auditMessage: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sip.people.UpdateUserRequest}
 */
proto.sip.people.UpdateUserRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sip.people.UpdateUserRequest;
  return proto.sip.people.UpdateUserRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sip.people.UpdateUserRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sip.people.UpdateUserRequest}
 */
proto.sip.people.UpdateUserRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = new proto.sip.people.User;
      reader.readMessage(value,proto.sip.people.User.deserializeBinaryFromReader);
      msg.setUser(value);
      break;
    case 3:
      var value = new google_protobuf_field_mask_pb.FieldMask;
      reader.readMessage(value,google_protobuf_field_mask_pb.FieldMask.deserializeBinaryFromReader);
      msg.setUpdateMask(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setAuditMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sip.people.UpdateUserRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sip.people.UpdateUserRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sip.people.UpdateUserRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.people.UpdateUserRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUser();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.sip.people.User.serializeBinaryToWriter
    );
  }
  f = message.getUpdateMask();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_field_mask_pb.FieldMask.serializeBinaryToWriter
    );
  }
  f = message.getAuditMessage();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.sip.people.UpdateUserRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.sip.people.UpdateUserRequest} returns this
 */
proto.sip.people.UpdateUserRequest.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional User user = 2;
 * @return {?proto.sip.people.User}
 */
proto.sip.people.UpdateUserRequest.prototype.getUser = function() {
  return /** @type{?proto.sip.people.User} */ (
    jspb.Message.getWrapperField(this, proto.sip.people.User, 2));
};


/**
 * @param {?proto.sip.people.User|undefined} value
 * @return {!proto.sip.people.UpdateUserRequest} returns this
*/
proto.sip.people.UpdateUserRequest.prototype.setUser = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sip.people.UpdateUserRequest} returns this
 */
proto.sip.people.UpdateUserRequest.prototype.clearUser = function() {
  return this.setUser(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sip.people.UpdateUserRequest.prototype.hasUser = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.FieldMask update_mask = 3;
 * @return {?proto.google.protobuf.FieldMask}
 */
proto.sip.people.UpdateUserRequest.prototype.getUpdateMask = function() {
  return /** @type{?proto.google.protobuf.FieldMask} */ (
    jspb.Message.getWrapperField(this, google_protobuf_field_mask_pb.FieldMask, 3));
};


/**
 * @param {?proto.google.protobuf.FieldMask|undefined} value
 * @return {!proto.sip.people.UpdateUserRequest} returns this
*/
proto.sip.people.UpdateUserRequest.prototype.setUpdateMask = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sip.people.UpdateUserRequest} returns this
 */
proto.sip.people.UpdateUserRequest.prototype.clearUpdateMask = function() {
  return this.setUpdateMask(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sip.people.UpdateUserRequest.prototype.hasUpdateMask = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string audit_message = 4;
 * @return {string}
 */
proto.sip.people.UpdateUserRequest.prototype.getAuditMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.sip.people.UpdateUserRequest} returns this
 */
proto.sip.people.UpdateUserRequest.prototype.setAuditMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sip.people.DeleteUserRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.sip.people.DeleteUserRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sip.people.DeleteUserRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.people.DeleteUserRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    auditMessage: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sip.people.DeleteUserRequest}
 */
proto.sip.people.DeleteUserRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sip.people.DeleteUserRequest;
  return proto.sip.people.DeleteUserRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sip.people.DeleteUserRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sip.people.DeleteUserRequest}
 */
proto.sip.people.DeleteUserRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAuditMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sip.people.DeleteUserRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sip.people.DeleteUserRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sip.people.DeleteUserRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.people.DeleteUserRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAuditMessage();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.sip.people.DeleteUserRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.sip.people.DeleteUserRequest} returns this
 */
proto.sip.people.DeleteUserRequest.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string audit_message = 2;
 * @return {string}
 */
proto.sip.people.DeleteUserRequest.prototype.getAuditMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.sip.people.DeleteUserRequest} returns this
 */
proto.sip.people.DeleteUserRequest.prototype.setAuditMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sip.people.AuditLogEntry.prototype.toObject = function(opt_includeInstance) {
  return proto.sip.people.AuditLogEntry.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sip.people.AuditLogEntry} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.people.AuditLogEntry.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    affectedResource: jspb.Message.getFieldWithDefault(msg, 2, ""),
    resourceType: jspb.Message.getFieldWithDefault(msg, 3, ""),
    createTime: (f = msg.getCreateTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    requestKey: jspb.Message.getFieldWithDefault(msg, 5, ""),
    executedByUser: jspb.Message.getFieldWithDefault(msg, 6, ""),
    auditMessage: jspb.Message.getFieldWithDefault(msg, 7, ""),
    requestObject: jspb.Message.getFieldWithDefault(msg, 8, ""),
    view: jspb.Message.getFieldWithDefault(msg, 9, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sip.people.AuditLogEntry}
 */
proto.sip.people.AuditLogEntry.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sip.people.AuditLogEntry;
  return proto.sip.people.AuditLogEntry.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sip.people.AuditLogEntry} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sip.people.AuditLogEntry}
 */
proto.sip.people.AuditLogEntry.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAffectedResource(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setResourceType(value);
      break;
    case 4:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreateTime(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequestKey(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setExecutedByUser(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setAuditMessage(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequestObject(value);
      break;
    case 9:
      var value = /** @type {!proto.sip.people.AuditLogEntry.View} */ (reader.readEnum());
      msg.setView(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sip.people.AuditLogEntry.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sip.people.AuditLogEntry.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sip.people.AuditLogEntry} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.people.AuditLogEntry.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAffectedResource();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getResourceType();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getCreateTime();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getRequestKey();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getExecutedByUser();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getAuditMessage();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getRequestObject();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getView();
  if (f !== 0.0) {
    writer.writeEnum(
      9,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.sip.people.AuditLogEntry.View = {
  AUDIT_LOG_ENTRY_VIEW_UNSPECIFIED: 0
};




if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sip.people.AuditLogEntry.Filter.prototype.toObject = function(opt_includeInstance) {
  return proto.sip.people.AuditLogEntry.Filter.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sip.people.AuditLogEntry.Filter} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.people.AuditLogEntry.Filter.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    affectedResource: jspb.Message.getFieldWithDefault(msg, 2, ""),
    resourceType: jspb.Message.getFieldWithDefault(msg, 3, ""),
    descending: jspb.Message.getBooleanFieldWithDefault(msg, 20, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sip.people.AuditLogEntry.Filter}
 */
proto.sip.people.AuditLogEntry.Filter.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sip.people.AuditLogEntry.Filter;
  return proto.sip.people.AuditLogEntry.Filter.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sip.people.AuditLogEntry.Filter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sip.people.AuditLogEntry.Filter}
 */
proto.sip.people.AuditLogEntry.Filter.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAffectedResource(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setResourceType(value);
      break;
    case 20:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDescending(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sip.people.AuditLogEntry.Filter.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sip.people.AuditLogEntry.Filter.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sip.people.AuditLogEntry.Filter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.people.AuditLogEntry.Filter.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAffectedResource();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getResourceType();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDescending();
  if (f) {
    writer.writeBool(
      20,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.sip.people.AuditLogEntry.Filter.SortBy = {
  NAME: 0,
  AFFECTED_RESOURCE: 1
};

/**
 * optional string name = 1;
 * @return {string}
 */
proto.sip.people.AuditLogEntry.Filter.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.sip.people.AuditLogEntry.Filter} returns this
 */
proto.sip.people.AuditLogEntry.Filter.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string affected_resource = 2;
 * @return {string}
 */
proto.sip.people.AuditLogEntry.Filter.prototype.getAffectedResource = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.sip.people.AuditLogEntry.Filter} returns this
 */
proto.sip.people.AuditLogEntry.Filter.prototype.setAffectedResource = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string resource_type = 3;
 * @return {string}
 */
proto.sip.people.AuditLogEntry.Filter.prototype.getResourceType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.sip.people.AuditLogEntry.Filter} returns this
 */
proto.sip.people.AuditLogEntry.Filter.prototype.setResourceType = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional bool descending = 20;
 * @return {boolean}
 */
proto.sip.people.AuditLogEntry.Filter.prototype.getDescending = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 20, false));
};


/**
 * @param {boolean} value
 * @return {!proto.sip.people.AuditLogEntry.Filter} returns this
 */
proto.sip.people.AuditLogEntry.Filter.prototype.setDescending = function(value) {
  return jspb.Message.setProto3BooleanField(this, 20, value);
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.sip.people.AuditLogEntry.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.sip.people.AuditLogEntry} returns this
 */
proto.sip.people.AuditLogEntry.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string affected_resource = 2;
 * @return {string}
 */
proto.sip.people.AuditLogEntry.prototype.getAffectedResource = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.sip.people.AuditLogEntry} returns this
 */
proto.sip.people.AuditLogEntry.prototype.setAffectedResource = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string resource_type = 3;
 * @return {string}
 */
proto.sip.people.AuditLogEntry.prototype.getResourceType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.sip.people.AuditLogEntry} returns this
 */
proto.sip.people.AuditLogEntry.prototype.setResourceType = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional google.protobuf.Timestamp create_time = 4;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.sip.people.AuditLogEntry.prototype.getCreateTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 4));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.sip.people.AuditLogEntry} returns this
*/
proto.sip.people.AuditLogEntry.prototype.setCreateTime = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sip.people.AuditLogEntry} returns this
 */
proto.sip.people.AuditLogEntry.prototype.clearCreateTime = function() {
  return this.setCreateTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sip.people.AuditLogEntry.prototype.hasCreateTime = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional string request_key = 5;
 * @return {string}
 */
proto.sip.people.AuditLogEntry.prototype.getRequestKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.sip.people.AuditLogEntry} returns this
 */
proto.sip.people.AuditLogEntry.prototype.setRequestKey = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string executed_by_user = 6;
 * @return {string}
 */
proto.sip.people.AuditLogEntry.prototype.getExecutedByUser = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.sip.people.AuditLogEntry} returns this
 */
proto.sip.people.AuditLogEntry.prototype.setExecutedByUser = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string audit_message = 7;
 * @return {string}
 */
proto.sip.people.AuditLogEntry.prototype.getAuditMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.sip.people.AuditLogEntry} returns this
 */
proto.sip.people.AuditLogEntry.prototype.setAuditMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string request_object = 8;
 * @return {string}
 */
proto.sip.people.AuditLogEntry.prototype.getRequestObject = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.sip.people.AuditLogEntry} returns this
 */
proto.sip.people.AuditLogEntry.prototype.setRequestObject = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional View view = 9;
 * @return {!proto.sip.people.AuditLogEntry.View}
 */
proto.sip.people.AuditLogEntry.prototype.getView = function() {
  return /** @type {!proto.sip.people.AuditLogEntry.View} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {!proto.sip.people.AuditLogEntry.View} value
 * @return {!proto.sip.people.AuditLogEntry} returns this
 */
proto.sip.people.AuditLogEntry.prototype.setView = function(value) {
  return jspb.Message.setProto3EnumField(this, 9, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sip.people.ListAuditLogEntriesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.sip.people.ListAuditLogEntriesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sip.people.ListAuditLogEntriesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.people.ListAuditLogEntriesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    pageSize: jspb.Message.getFieldWithDefault(msg, 1, 0),
    pageToken: jspb.Message.getFieldWithDefault(msg, 2, ""),
    filter: (f = msg.getFilter()) && proto.sip.people.AuditLogEntry.Filter.toObject(includeInstance, f),
    view: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sip.people.ListAuditLogEntriesRequest}
 */
proto.sip.people.ListAuditLogEntriesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sip.people.ListAuditLogEntriesRequest;
  return proto.sip.people.ListAuditLogEntriesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sip.people.ListAuditLogEntriesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sip.people.ListAuditLogEntriesRequest}
 */
proto.sip.people.ListAuditLogEntriesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPageSize(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPageToken(value);
      break;
    case 3:
      var value = new proto.sip.people.AuditLogEntry.Filter;
      reader.readMessage(value,proto.sip.people.AuditLogEntry.Filter.deserializeBinaryFromReader);
      msg.setFilter(value);
      break;
    case 4:
      var value = /** @type {!proto.sip.people.AuditLogEntry.View} */ (reader.readEnum());
      msg.setView(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sip.people.ListAuditLogEntriesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sip.people.ListAuditLogEntriesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sip.people.ListAuditLogEntriesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.people.ListAuditLogEntriesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPageSize();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getPageToken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getFilter();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.sip.people.AuditLogEntry.Filter.serializeBinaryToWriter
    );
  }
  f = message.getView();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
};


/**
 * optional int32 page_size = 1;
 * @return {number}
 */
proto.sip.people.ListAuditLogEntriesRequest.prototype.getPageSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.sip.people.ListAuditLogEntriesRequest} returns this
 */
proto.sip.people.ListAuditLogEntriesRequest.prototype.setPageSize = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string page_token = 2;
 * @return {string}
 */
proto.sip.people.ListAuditLogEntriesRequest.prototype.getPageToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.sip.people.ListAuditLogEntriesRequest} returns this
 */
proto.sip.people.ListAuditLogEntriesRequest.prototype.setPageToken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional AuditLogEntry.Filter filter = 3;
 * @return {?proto.sip.people.AuditLogEntry.Filter}
 */
proto.sip.people.ListAuditLogEntriesRequest.prototype.getFilter = function() {
  return /** @type{?proto.sip.people.AuditLogEntry.Filter} */ (
    jspb.Message.getWrapperField(this, proto.sip.people.AuditLogEntry.Filter, 3));
};


/**
 * @param {?proto.sip.people.AuditLogEntry.Filter|undefined} value
 * @return {!proto.sip.people.ListAuditLogEntriesRequest} returns this
*/
proto.sip.people.ListAuditLogEntriesRequest.prototype.setFilter = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sip.people.ListAuditLogEntriesRequest} returns this
 */
proto.sip.people.ListAuditLogEntriesRequest.prototype.clearFilter = function() {
  return this.setFilter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sip.people.ListAuditLogEntriesRequest.prototype.hasFilter = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional AuditLogEntry.View view = 4;
 * @return {!proto.sip.people.AuditLogEntry.View}
 */
proto.sip.people.ListAuditLogEntriesRequest.prototype.getView = function() {
  return /** @type {!proto.sip.people.AuditLogEntry.View} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.sip.people.AuditLogEntry.View} value
 * @return {!proto.sip.people.ListAuditLogEntriesRequest} returns this
 */
proto.sip.people.ListAuditLogEntriesRequest.prototype.setView = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.sip.people.ListAuditLogEntriesResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sip.people.ListAuditLogEntriesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.sip.people.ListAuditLogEntriesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sip.people.ListAuditLogEntriesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.people.ListAuditLogEntriesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    auditLogEntriesList: jspb.Message.toObjectList(msg.getAuditLogEntriesList(),
    proto.sip.people.AuditLogEntry.toObject, includeInstance),
    nextPageToken: jspb.Message.getFieldWithDefault(msg, 2, ""),
    totalSize: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sip.people.ListAuditLogEntriesResponse}
 */
proto.sip.people.ListAuditLogEntriesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sip.people.ListAuditLogEntriesResponse;
  return proto.sip.people.ListAuditLogEntriesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sip.people.ListAuditLogEntriesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sip.people.ListAuditLogEntriesResponse}
 */
proto.sip.people.ListAuditLogEntriesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.sip.people.AuditLogEntry;
      reader.readMessage(value,proto.sip.people.AuditLogEntry.deserializeBinaryFromReader);
      msg.addAuditLogEntries(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setNextPageToken(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotalSize(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sip.people.ListAuditLogEntriesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sip.people.ListAuditLogEntriesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sip.people.ListAuditLogEntriesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.people.ListAuditLogEntriesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAuditLogEntriesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.sip.people.AuditLogEntry.serializeBinaryToWriter
    );
  }
  f = message.getNextPageToken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTotalSize();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
};


/**
 * repeated AuditLogEntry audit_log_entries = 1;
 * @return {!Array<!proto.sip.people.AuditLogEntry>}
 */
proto.sip.people.ListAuditLogEntriesResponse.prototype.getAuditLogEntriesList = function() {
  return /** @type{!Array<!proto.sip.people.AuditLogEntry>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.sip.people.AuditLogEntry, 1));
};


/**
 * @param {!Array<!proto.sip.people.AuditLogEntry>} value
 * @return {!proto.sip.people.ListAuditLogEntriesResponse} returns this
*/
proto.sip.people.ListAuditLogEntriesResponse.prototype.setAuditLogEntriesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.sip.people.AuditLogEntry=} opt_value
 * @param {number=} opt_index
 * @return {!proto.sip.people.AuditLogEntry}
 */
proto.sip.people.ListAuditLogEntriesResponse.prototype.addAuditLogEntries = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.sip.people.AuditLogEntry, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sip.people.ListAuditLogEntriesResponse} returns this
 */
proto.sip.people.ListAuditLogEntriesResponse.prototype.clearAuditLogEntriesList = function() {
  return this.setAuditLogEntriesList([]);
};


/**
 * optional string next_page_token = 2;
 * @return {string}
 */
proto.sip.people.ListAuditLogEntriesResponse.prototype.getNextPageToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.sip.people.ListAuditLogEntriesResponse} returns this
 */
proto.sip.people.ListAuditLogEntriesResponse.prototype.setNextPageToken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 total_size = 3;
 * @return {number}
 */
proto.sip.people.ListAuditLogEntriesResponse.prototype.getTotalSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.sip.people.ListAuditLogEntriesResponse} returns this
 */
proto.sip.people.ListAuditLogEntriesResponse.prototype.setTotalSize = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * @enum {number}
 */
proto.sip.people.GlobalScope = {
  GLOBAL_SCOPE_UNSPECIFIED: 0,
  ADMIN: 1,
  GROUPS_LIST: 2,
  GROUPS_CREATE: 7,
  GROUPS_CREATE_TAG: 30,
  GROUPS_UPDATE: 8,
  GROUPS_UPDATE_TAG: 31,
  GROUPS_DELETE: 9,
  GROUPS_DELETE_TAG: 32,
  USERS_LIST: 10,
  USERS_CREATE: 11,
  USERS_UPDATE: 12,
  USERS_DELETE: 13,
  USERS_GET: 14,
  USERS_GET_PROFILE: 15,
  USERS_GET_EMAIL: 16,
  USERS_GET_PHONE: 17,
  USERS_GET_ADDRESS: 18,
  USERS_GET_BIRTHDATE: 19,
  USERS_GET_UNIVERSITY_AFFILIATION: 20,
  USERS_GET_STUDENT_NUMBER: 21,
  USERS_GET_STUDY_INFORMATION: 22,
  USERS_GET_VSETH_PROFILE: 23,
  USERS_GET_GROUP_MEMBERSHIP: 28,
  USERS_GET_RFID_INFORMATION: 29,
  USERS_GET_BANK_ACCOUNT: 24,
  USERS_GET_ALL: 25,
  AUDITLOG_LIST: 26,
  LDAPSYNC_TRIGGER: 27
};

/**
 * @enum {number}
 */
proto.sip.people.ResourceScope = {
  RESOURCE_SCOPE_UNSPECIFIED: 0,
  GROUPS_GET: 4,
  GROUPS_MEMBERS_GET: 5,
  GROUPS_MEMBERS_UPDATE: 6
};

/**
 * @enum {number}
 */
proto.sip.people.GroupObjectType = {
  GROUP_OBJECT_TYPE_UNSPECIFIED: 0,
  GROUP_OBJECT_TYPE_KS: 1,
  GROUP_OBJECT_TYPE_OU: 2,
  GROUP_OBJECT_TYPE_OIOE: 3,
  GROUP_OBJECT_TYPE_FIOE: 4,
  GROUP_OBJECT_TYPE_TAG: 5
};

/**
 * @enum {number}
 */
proto.sip.people.VsethMembership = {
  VSETH_MEMBERSHIP_UNSPECIFIED: 0,
  VSETH_MEMBERSHIP_MEMBER: 1,
  VSETH_MEMBERSHIP_NOT_MEMBER: 2
};

/**
 * @enum {number}
 */
proto.sip.people.VsethMembershipType = {
  VSETH_MEMBERSHIP_TYPE_UNSPECIFIED: 0,
  VSETH_MEMBERSHIP_TYPE_BACHELOR_OR_MASTER: 1,
  VSETH_MEMBERSHIP_TYPE_PHD: 2,
  VSETH_MEMBERSHIP_TYPE_VSETH_EMPLOYEES: 3,
  VSETH_MEMBERSHIP_TYPE_ETH_APPRENTICES: 4,
  VSETH_MEMBERSHIP_TYPE_MOBILITY_STUDENTS: 5,
  VSETH_MEMBERSHIP_TYPE_STUDENTS_IN_CONTINUING_EDUCATION: 6,
  VSETH_MEMBERSHIPO_TYPE_AUDITORS: 7
};

goog.object.extend(exports, proto.sip.people);
