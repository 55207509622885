import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Accordion, Button, Container, Grid, Table } from "@mantine/core";
import {
  selectDisableOISFieldsForUser,
  useGetUserQuery,
  VsethMembershipInfo,
} from "../features/user/userSlice";
import {
  VsethMembership,
  VsethMembershipType,
} from "../proto/sip/people/people_pb";
import { LoadScreen } from "../components/LoadScreen";
import { ErrorScreen } from "../components/ErrorScreen";
import { useTranslation } from "react-i18next";
import { formatDate } from "../util/proto";
import TdWithExplanationIcon from "../components/TdWithExplanation";
import ExplanationModal from "../components/ExplanationModel";
import {
  hideExplanation,
  selectExplanation,
  selectOpenExplanation,
  showExplanation,
} from "../features/explanation/explanationSlice";
import { Group } from "../components/Group";
import { selectUserInfo } from "../features/auth/authSlice";

// AccountPage renders a view of tables displaying user information
// and buttons to edit it
export const AccountPage = () => {
  const sub = (useSelector(selectUserInfo) as unknown as { sub: string }).sub;
  const {
    data: user,
    isLoading,
    isFetching: isUpdating,
    error,
    refetch,
  } = useGetUserQuery(
    { name: `users/${sub}` }, {}
  );
  const disableOISFields = useSelector(selectDisableOISFieldsForUser);
  const explanation = useSelector(selectExplanation);
  const openExplanation = useSelector(selectOpenExplanation);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const setExplanationOisField = () => {
    if (disableOISFields) {
      dispatch(showExplanation("oisField"));
    } else {
      dispatch(showExplanation("vsethField"));
    }
  };

  // "toString" for VsethMembership
  const formatVsethMembership = (
    membershipInfo?: VsethMembershipInfo
  ): string => {
    if (!membershipInfo) {
      return t("AccountPage.vsethMembership.notMember");
    }

    switch (membershipInfo.membership) {
      case VsethMembership.VSETH_MEMBERSHIP_MEMBER:
        return t("AccountPage.vsethMembership.member");
      case VsethMembership.VSETH_MEMBERSHIP_NOT_MEMBER:
        return t("AccountPage.vsethMembership.notMember");
      default:
        return t("AccountPage.vsethMembership.unspecified");
    }
  };

  // "toString" for VsethMembershipType
  const formatVsethMembershipType = (
    membershipType: VsethMembershipType
  ): string => {
    switch (membershipType) {
      case VsethMembershipType.VSETH_MEMBERSHIP_TYPE_BACHELOR_OR_MASTER:
        return t("AccountPage.vsethMembershipType.bachelorOrMaster");
      case VsethMembershipType.VSETH_MEMBERSHIP_TYPE_PHD:
        return t("AccountPage.vsethMembershipType.phd");
      case VsethMembershipType.VSETH_MEMBERSHIP_TYPE_VSETH_EMPLOYEES:
        return t("AccountPage.vsethMembershipType.vsethEmployees");
      case VsethMembershipType.VSETH_MEMBERSHIP_TYPE_ETH_APPRENTICES:
        return t("AccountPage.vsethMembershipType.ethApprentice");
      case VsethMembershipType.VSETH_MEMBERSHIP_TYPE_MOBILITY_STUDENTS:
        return t("AccountPage.vsethMembershipType.mobilityStudents");
      case VsethMembershipType.VSETH_MEMBERSHIP_TYPE_STUDENTS_IN_CONTINUING_EDUCATION:
        return t("AccountPage.vsethMembershipType.inContinuingEducation");
      case VsethMembershipType.VSETH_MEMBERSHIPO_TYPE_AUDITORS:
        return t("AccountPage.vsethMembershipType.auditors");
      default:
        return t("AccountPage.vsethMembershipType.unspecified");
    }
  };

  if (isLoading || isUpdating) {
    return <LoadScreen />;
  }
  if (error || !user) {
    return <ErrorScreen />;
  }

  return (
    <Container
    >
      <Button
        color="primary"
        className="float-right mb-3"
        onClick={() => refetch()}
      >
        {t("buttons.reload")}
      </Button>
      <h1>{t("AccountPage.accountInfo")}</h1>
      <section className="mb-5">
        <h2>{t("AccountPage.basicInfo")}</h2>
        <Table>
          <tbody>
            <tr>
              <td>
                <b>{t("AccountPage.username")}</b>
              </td>
              <TdWithExplanationIcon
                onClick={() => dispatch(showExplanation("oisField"))}
              >
                {user.username}
              </TdWithExplanationIcon>
            </tr>
            <tr>
              <td>
                <b>{t("AccountPage.firstName")}</b>
              </td>
              <TdWithExplanationIcon
                onClick={() => dispatch(showExplanation("oisField"))}
              >
                {user.givenName}
              </TdWithExplanationIcon>
            </tr>
            <tr>
              <td>
                <b>{t("AccountPage.lastName")}</b>
              </td>
              <TdWithExplanationIcon
                onClick={() => dispatch(showExplanation("oisField"))}
              >
                {user.familyName}
              </TdWithExplanationIcon>
            </tr>
            <tr>
              <td>
                <b>{t("AccountPage.birthdate")}</b>
              </td>
              <TdWithExplanationIcon
                onClick={() => dispatch(showExplanation("vsethField"))}
              >
                {formatDate(user.birthdate)}
              </TdWithExplanationIcon>
            </tr>
            <tr>
              <td>
                <b>{t("AccountPage.email")}</b>
              </td>
              <TdWithExplanationIcon
                onClick={() => dispatch(showExplanation("oisField"))}
              >
                {user.email}
              </TdWithExplanationIcon>
            </tr>
            <tr>
              <td>
                <b>{t("AccountPage.telephone")}</b>
              </td>
              <TdWithExplanationIcon
                onClick={() => dispatch(showExplanation("vsethField"))}
              >
                {user.telephone}
              </TdWithExplanationIcon>
            </tr>
            <tr>
              <td>
                <b>{t("AccountPage.swissEduIdentifier")}</b>
              </td>
              <TdWithExplanationIcon
                onClick={() => dispatch(showExplanation("uniField"))}
              >
                {user.swissEduPersonUniqueId}
              </TdWithExplanationIcon>
            </tr>
            {user.studentNumber !== "" && (
              <tr>
                <td>
                  <b>{t("AccountPage.studentNumber")}</b>
                </td>
                <TdWithExplanationIcon
                  onClick={() => dispatch(showExplanation("uniField"))}
                >
                  {user.studentNumber}
                </TdWithExplanationIcon>
              </tr>
            )}
            {user.studySemester > 0 && (
              <tr>
                <td>
                  <b>{t("AccountPage.studySemester")}</b>
                </td>
                <TdWithExplanationIcon
                  onClick={() => dispatch(showExplanation("uniField"))}
                >
                  {user.studySemester}
                </TdWithExplanationIcon>
              </tr>
            )}
            {/*}
                        {user.ethFachid !== "" &&
                            (
                                <tr>
                                    <td><b>{t("AccountPage.ethFachId")}</b></td>
                                    <TdWithExplanationIcon onClick={() => dispatch(showExplanation("uniField"))}>{user.ethFachid}</TdWithExplanationIcon>
                                </tr>
                            )
                        }
                        */}
            <tr>
              <td>
                <b>{t("AccountPage.vsethMembership.title")}</b>
              </td>
              <TdWithExplanationIcon
                onClick={() => dispatch(showExplanation("vsethFixedField"))}
              >
                {formatVsethMembership(user.vsethMembershipInfo)}
                {(user.vsethMembershipInfo?.membership === VsethMembership.VSETH_MEMBERSHIP_MEMBER) && ", " + formatVsethMembershipType(user.vsethMembershipInfo.membershipType)}
              </TdWithExplanationIcon>
            </tr>
            {/*
                        {user.vsethMembership === VsethMembership.VSETH_MEMBERSHIP_MEMBER &&
                            (
                                <tr>
                                    <td><b>{t("AccountPage.vsethMembershipType.title")}</b></td>
                                    <TdWithExplanationIcon onClick={() => dispatch(showExplanation("vsethFixedField"))}>{formatVsethMembershipType(user.vsethMembershipType)}</TdWithExplanationIcon>
                                </tr>
                            )
                        }
                        // TODO: show this conditionally (but what is the condition?)
                        <tr>
                            <td><b>{t("AccountPage.vsethMembership.exitDate")}</b></td>
                            <TdWithExplanationIcon onClick={() => dispatch(showExplanation("vsethFixedField"))}>{formatDate(user.vsethMembershipExitDate)}</TdWithExplanationIcon>
                        </tr>
                        */}
            {user.groupMembershipList.length > 0 && (
              <tr>
                <td>
                  <b>{t("AccountPage.groupMembership")}</b>
                </td>
                <TdWithExplanationIcon
                  onClick={() => dispatch(showExplanation("vsethFixedField"))}
                >
                  <Accordion
                    defaultValue="groups"
                    variant="default"
                    styles={{
                      item: {
                        borderBottom: "none",
                        '&:hover': { backgroundColor: "none" },
                      },
                    }}
                  >
                    <Accordion.Item value="groups">
                      <Accordion.Control style={{ fontSize: "1em", padding: "0" }}>Groups</Accordion.Control>
                      <Accordion.Panel>
                        <Grid>
                          {user.groupMembershipList.map((group, index) => (
                            <React.Fragment key={group}>
                              <Group input={group}></Group>
                              {index !== user.groupMembershipList.length - 1 && <br />}
                            </React.Fragment>
                          ))}
                        </Grid>
                      </Accordion.Panel>
                    </Accordion.Item>
                  </Accordion>
                </TdWithExplanationIcon>
              </tr>
            )}
          </tbody>
        </Table>
        <Button
          color="primary"
          className="float-right mb-3"
          onClick={(e) => navigate("/account/edit/basic")}
        >
          {t("buttons.edit")}
        </Button>
      </section>
      <section className="mb-5">
        <h2>{t("AccountPage.address")}</h2>
        {!user.postalAddress ? (
          <Button
            color="primary"
            className="float-right mb-3"
            onClick={(e) => navigate("/account/edit/address")}
          >
            {t("buttons.addPostalAddress")}
          </Button>
        ) : (
          <div>
            <Table>
              <tbody>
                <tr>
                  <td>
                    <b>{t("AccountPage.street")}</b>
                  </td>
                  <TdWithExplanationIcon onClick={setExplanationOisField}>
                    {user.postalAddress.streetAddress}
                  </TdWithExplanationIcon>
                </tr>
                <tr>
                  <td>
                    <b>{t("AccountPage.locality")}</b>
                  </td>
                  <TdWithExplanationIcon onClick={setExplanationOisField}>
                    {user.postalAddress.locality}
                  </TdWithExplanationIcon>
                </tr>
                <tr>
                  <td>
                    <b>{t("AccountPage.postalCode")}</b>
                  </td>
                  <TdWithExplanationIcon onClick={setExplanationOisField}>
                    {user.postalAddress.postalCode}
                  </TdWithExplanationIcon>
                </tr>
                <tr>
                  <td>
                    <b>{t("AccountPage.country")}</b>
                  </td>
                  <TdWithExplanationIcon onClick={setExplanationOisField}>
                    {user.postalAddress.country}
                  </TdWithExplanationIcon>
                </tr>
              </tbody>
            </Table>
            <Button
              color="primary"
              className="float-right mb-3"
              disabled={disableOISFields}
              onClick={(e) => navigate("/account/edit/address")}
            >
              {t("buttons.edit")}
            </Button>
          </div>
        )}
      </section>
      <section className="mb-5">
        <h2>{t("AccountPage.bankAccount")}</h2>
        {!user.bankAccount ? (
          <Button
            color="primary"
            className="float-right mb-3"
            onClick={(e) => navigate("/account/edit/bankaccount")}
          >
            {t("buttons.addBankAccount")}
          </Button>
        ) : (
          <div>
            <Table>
              <tbody>
                <tr>
                  <td>
                    <b>{t("AccountPage.accountHolderName")}</b>
                  </td>
                  <TdWithExplanationIcon
                    onClick={() => dispatch(showExplanation("vsethField"))}
                  >
                    {user.bankAccount.accountHolderName}
                  </TdWithExplanationIcon>
                </tr>
                <tr>
                  <td>
                    <b>{t("AccountPage.iban")}</b>
                  </td>
                  <TdWithExplanationIcon
                    onClick={() => dispatch(showExplanation("vsethField"))}
                  >
                    {user.bankAccount.iban}
                  </TdWithExplanationIcon>
                </tr>
                <tr>
                  <td>
                    <b>{t("AccountPage.bic")}</b>
                  </td>
                  <TdWithExplanationIcon
                    onClick={() => dispatch(showExplanation("vsethField"))}
                  >
                    {user.bankAccount.bic}
                  </TdWithExplanationIcon>
                </tr>
                <tr>
                  <td>
                    <b>{t("AccountPage.bankName")}</b>
                  </td>
                  <TdWithExplanationIcon
                    onClick={() => dispatch(showExplanation("vsethField"))}
                  >
                    {user.bankAccount.bankName}
                  </TdWithExplanationIcon>
                </tr>
                <tr>
                  <td>
                    <b>{t("AccountPage.bankingAddress")}</b>
                  </td>
                  <TdWithExplanationIcon
                    onClick={() => dispatch(showExplanation("vsethField"))}
                  >
                    {!user.bankAccount.bankingAddress
                      ? t("AccountPage.noBankingAddress")
                      : `${user.bankAccount.bankingAddress.streetAddress}, 
                                                ${user.bankAccount.bankingAddress.postalCode} ${user.bankAccount.bankingAddress.locality}, 
                                                ${user.bankAccount.bankingAddress.country}
                                                `}
                  </TdWithExplanationIcon>
                </tr>
              </tbody>
            </Table>
            <Button
              color="primary"
              className="float-right mb-5"
              onClick={(e) => navigate("/account/edit/bankaccount")}
            >
              {t("buttons.edit")}
            </Button>
          </div>
        )}
      </section>
      <ExplanationModal
        isOpen={openExplanation}
        explanation={explanation}
        onClose={() => dispatch(hideExplanation())}
      />
    </Container>
  );
};
